import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';
import AddServiceModalHook from './AddServiceModalHook';
import DeleteServiceModalHook from './DeleteServiceModalHook';
import EditServiceModalHook from './EditServiceModalHook';

const PricesFormHook = (props) => {
    // Declare state variables, which we'll call "values"
    const [values, setValues] = useState({ //'values' used to get the data  &  'setValues' used to set the data
        loading: false,
        //description: '',
        servicesArray: null, //first time it will be undefined ('null' from redux), later we assign it
    })

    useEffect(() => { // Similar to componentDidMount and componentDidUpdate:
        onListenForData();

        return () => { // Specify how to clean up after this effect: //remove the listener to avoid memory leaks

        };

    }, []);


    //(using Cloud Firestore)
    const onListenForData = () => {
        setValues({
            ...values, //we leave the states as they are
            loading: true, //and update only this
        })


        const unsubscribe =
            props.firebase
                .services()
                .orderBy('order', 'asc') //desc
                .onSnapshot(snapshot => {
                    if (snapshot.size) { //if snapshot contains data
                        // console.log("snapshot.size: " + snapshot.size);

                        let servicesArray = [];

                        snapshot.forEach(doc =>
                            //for each data 'doc' (document - this is how it's referred in firebase db) that the 'snapshot' contains, we will push/insert it into the array using '...doc.data()' + we add an additional field called 'id' with the value of 'doc.id'
                            servicesArray.push({ ...doc.data(), id: doc.id }),
                        );

                        //console.log("servicesArray.length: " + servicesArray.length);

                        props.setReduxServicesArray(servicesArray);

                        //console.log("props/redux: "+this.props.getReduxServicesArray);

                        setValues({
                            //...inputValues, //we leave the states as they are
                            loading: false, //and update only this
                            servicesArray: servicesArray,
                        })

                        //console.log("length in state (after redux): " + this.state.servicesArray.length);

                    } else {
                        setValues({
                            //...inputValues, //we leave the states as they are
                            loading: false, //and update only this
                            servicesArray: null
                        })

                        props.setReduxServicesArray(null); //this is needed, because if we have some data, then we delete all of them, so we don't have any data left, we want to reset the 'servicesArray' in our reducer back to null.
                    }
                    //console.log("State: "+this.state.servicesArray);
                });
    }


    const onUp = (x) => { //event and service (called 'x')
        //console.log("up");
        //console.log(x.order);
        if (x.order === 1) {
            console.log("already the first");
        } else {
            //find the 'id' and 'order' number for the row above
            let orderOfRowAbove = x.order - 1; //the 'order' number of the row above is '-1' less (therefore we minus)
            let rowAbove = props.getReduxServicesArray[orderOfRowAbove - 1]; //then we get the complete data of the row above. We say '-1' because the array starts from '0'.
            //let idOfRowAbove = rowAbove.id; //get the id of the row above

            //decrease the 'order' number of the targeted row ('service') (so it can go up)
            props.firebase.service(x.id).set({ //for the service in the targeted row (called 'x') we:
                //...x, //we keep all data (NO! If I do this, it will also add the 'id' as a field - I don't want that)
                name: x.name,
                description: x.description,
                price: x.price,
                order: x.order - 1, //decrease the 'order' number (so it can go up (smallest first))
                iconNr: x.iconNr,
            });

            //increase the 'order' number of the above service (so it can go down)
            props.firebase.service(rowAbove.id).set({ //for the service in the above row (called 'rowAbove') we:
                //...rowAbove, //we keep all data
                name: rowAbove.name,
                description: rowAbove.description,
                price: rowAbove.price,
                order: orderOfRowAbove + 1, //increase the 'order' number (so it can go down (biggest last))
                iconNr: rowAbove.iconNr,
            });
        }
        //event.preventDefault();
    }


    const onDown = (x) => {
        //console.log("down");
        //console.log(x.order);
        if (x.order === (props.getReduxServicesArray.length)) {
            console.log("already the last");
        } else {
            //find the 'id' and 'order' number for the row below
            let orderOfRowBelow = x.order + 1; //the 'order' number of the row below is '+1' more (therefore we plus/add)
            let rowBelow = props.getReduxServicesArray[orderOfRowBelow - 1]; //then we get the complete data of the row above. We say '-1' because the array starts from '0'.
            //let idOfRowBelow = rowBelow.id; //get the id of the row below

            //increase the 'order' number of the targeted row ('service') (so it can go down)
            props.firebase.service(x.id).set({ //for the service in the targeted row (called 'x') we:
                //...x, //we keep all data (NO! If I do this, it will also add the 'id' as a field - I don't want that)
                name: x.name,
                description: x.description,
                price: x.price,
                order: x.order + 1, //increase the 'order' number (so it can go down (biggest last))
                iconNr: x.iconNr,
            });

            //decrease the 'order' number of the below row ('service') (so it can go op)
            props.firebase.service(rowBelow.id).set({  //for the service in the below row (called 'rowBelow') we:
                //...rowBelow, //we keep all data
                name: rowBelow.name,
                description: rowBelow.description,
                price: rowBelow.price,
                order: orderOfRowBelow - 1, //decrease the 'order' number (so it can go op (smallest first))
                iconNr: rowBelow.iconNr,
            });
        }
        //event.preventDefault();
    }

    const onOpenAddServiceModal = () => {
        props.setReduxShowAddServiceModal(true);
    }

    const onOpenDeleteServiceModal = (x) => {
        props.setReduxShowDeleteServiceModal(true);
        props.setReduxTargetedService(x);
    }

    const onOpenEditServiceModal = (x) => {
        props.setReduxShowEditServiceModal(true);
        props.setReduxTargetedService(x);
    }

    const { getReduxShowAddServiceModal, getReduxShowDeleteServiceModal, getReduxShowEditServiceModal } = props;


    return (
        <div>

            <div className="center-this">
                {values.loading && <div>Loading ...</div>} {/*if loading is defined then..*/}
            </div>

            <div className="tableArea">
                <button type="button" className="addButton font-this" onClick={() => onOpenAddServiceModal()}>+ Opret service</button>
            </div>

            {getReduxShowAddServiceModal === true ? (  //if 'getReduxShowAddServiceModal' is true, then display modal
                <AddServiceModalHook />
            ) : ( //else
                    null //do nothing
                )}

            {getReduxShowDeleteServiceModal === true ? (  //if 'getReduxShowDeleteServiceModal' is true, then display modal
                <DeleteServiceModalHook />
            ) : ( //else
                    null //do nothing
                )}

            {getReduxShowEditServiceModal === true ? (  //if 'getReduxShowEditServiceModal' is true, then display modal
                <EditServiceModalHook />
            ) : ( //else
                    null //do nothing
                )}



            <div>

                <table className="table-center marginBottomBig">
                    <thead>
                        <tr>
                            <th className="paddingLeft paddingRight">Nr.</th>
                            <th className="paddingLeft paddingRight">Navn</th>
                            <th className="paddingLeft paddingRight">Beskrivelse</th>
                            <th className="paddingLeft paddingRight">Pris</th>
                            <th className="paddingLeft paddingRight">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values.servicesArray && values.servicesArray.map(x => ( // if servicesArray is defined then map..
                            <tr key={x.id}>
                                <td className="center-this" >{x.order}</td>
                                <td className="paddingLeft paddingRight ellipsisName">{x.name}</td>
                                <td className="paddingLeft paddingRight ellipsisDescription">{x.description}</td>
                                <td className="center-this paddingLeft paddingRight ellipsisPrice">{x.price}</td>
                                <td className="center-this paddingLeft paddingRight">
                                    <button type="button" className="table-button-padding" onClick={() => onUp(x)}>Op</button>
                                    <button type="button" className="table-button-padding" onClick={() => onDown(x)}>Ned</button>
                                    <button type="button" className="table-button-padding" onClick={() => onOpenEditServiceModal(x)} >Rediger</button>
                                    <button type="button" className="table-button-padding" onClick={() => onOpenDeleteServiceModal(x)} >Slet</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

//get
const mapStateToProps = (state) => ({
    getReduxServicesArray: state.servicesState.servicesArray, //get the state from servicesState -> servicesArray
    getReduxShowAddServiceModal: state.servicesState.showAddServiceModal,
    getReduxShowDeleteServiceModal: state.servicesState.showDeleteServiceModal,
    getReduxShowEditServiceModal: state.servicesState.showEditServiceModal,
    //getReduxTargetedService: state.servicesState.targetedService,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxServicesArray: (x) =>
        dispatch({ type: 'SERVICES_SET', x}),

    setReduxShowAddServiceModal: (x) =>
        dispatch({ type: 'SHOW_ADD_SERVICE_MODAL', x}),

    setReduxShowDeleteServiceModal: (x) =>
        dispatch({ type: 'SHOW_DELETE_SERVICE_MODAL', x}),

    setReduxShowEditServiceModal: (x) =>
        dispatch({ type: 'SHOW_EDIT_SERVICE_MODAL', x}),

    setReduxTargetedService: (x) =>
        dispatch({ type: 'SET_TARGETED_SERVICE', x}),

})

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(PricesFormHook);
