/* The user reducer deals with the list of users from the Firebase realtime database. It sets either the whole object of
 users as dictionary, or a single user identified by a unique identifier */

const INITIAL_STATE = {
    users: null,
};



function userReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'USERS_SET': return { 
            ...state,
            users: action.users
        }
        case 'USER_SET': return {
            ...state,
            users: {
                ...state.users,
                [action.uid]: action.user,
              },
        }

        default:
            return state; //if we don't have the type defined in the reducer, we just return the state
    }
}

export default userReducer;
