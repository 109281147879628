
 const INITIAL_STATE = {
    servicesArray: null,
    showAddServiceModal: "false",
    showDeleteServiceModal: "false",
    showEditServiceModal: "false",
    targetedService: null,
    isSaveSuccess: false,
};



function servicesReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'SERVICES_SET': return { 
            ...state,
            servicesArray: action.x
        }

        case 'SHOW_ADD_SERVICE_MODAL': return { 
            ...state,
            showAddServiceModal: action.x
        }

        case 'SHOW_DELETE_SERVICE_MODAL': return { 
            ...state,
            showDeleteServiceModal: action.x,
            //targetedService: action.x,
            /*deleteServiceWithId: action.x,
            deleteServiceWithName: action.x.name,*/
        }

        case 'SHOW_EDIT_SERVICE_MODAL': return { 
            ...state,
            showEditServiceModal: action.x,
        }

        case 'SET_TARGETED_SERVICE': return { 
            ...state,
            targetedService: action.x
        }

        case 'ISSAVESUCCESS_SET': return {
            ...state,
            isSaveSuccess: action.x
        }

        

        default:
            return state;
    }
}

export default servicesReducer;
