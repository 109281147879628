import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';
import AddEmployeeModalHook from './AddEmployeeModalHook';
import DeleteEmployeeModalHook from './DeleteEmployeeModalHook';
import EditEmployeeModalHook from './EditEmployeeModalHook';

const TeamFormHook = (props) => {
    // Declare state variables, which we'll call "values"
    const [values, setValues] = useState({ //'values' used to get the data  &  'setValues' used to set the data
        loading: false,
        employeesArray: null, //first time it will be undefined ('null' from redux), later we assign it
    })

    useEffect(() => { // Similar to componentDidMount and componentDidUpdate:
        onListenForData();

        return () => { // Specify how to clean up after this effect: //remove the listener to avoid memory leaks

        };

    }, []);


    //(using Cloud Firestore)
    const onListenForData = () => {
        setValues({
            ...values, //we leave the states as they are
            loading: true, //and update only this
        })


        const unsubscribe =
            props.firebase
                .employees()
                .orderBy('order', 'asc') //desc
                .onSnapshot(snapshot => {
                    if (snapshot.size) { //if snapshot contains data
                        // console.log("snapshot.size: " + snapshot.size);

                        let employeesArray = [];

                        snapshot.forEach(doc =>
                            //for each data 'doc' (document - this is how it's referred in firebase db) that the 'snapshot' contains, we will push/insert it into the array using '...doc.data()' + we add an additional field called 'id' with the value of 'doc.id'
                            employeesArray.push({ ...doc.data(), id: doc.id }),
                        );

                        props.setReduxEmployeesArray(employeesArray);

                        setValues({
                            loading: false, //and update only this
                            employeesArray: employeesArray,
                        })

                    } else {
                        setValues({
                            //...inputValues, //we leave the states as they are
                            loading: false, //and update only this
                            employeesArray: null
                        })

                        props.setReduxEmployeesArray(null); //this is needed, because if we have some data, then we delete all of them, so we don't have any data left, we want to reset the 'employeesArray' in our reducer back to null.
                    }
                });
    }


    const onUp = (x) => { //event and employee (called 'x')
        if (x.order === 1) {
            console.log("already the first");
        } else {
            //find the 'id' and 'order' number for the row above
            let orderOfRowAbove = x.order - 1; //the 'order' number of the row above is '-1' less (therefore we minus)
            let rowAbove = props.getReduxEmployeesArray[orderOfRowAbove - 1]; //then we get the complete data of the row above. We say '-1' because the array starts from '0'.
            //let idOfRowAbove = rowAbove.id; //get the id of the row above

            //decrease the 'order' number of the targeted row ('employee') (so it can go up)
            props.firebase.employee(x.id).set({ //for the employee in the targeted row (called 'x') we:
                //...x, //we keep all data (NO! If I do this, it will also add the 'id' as a field - I don't want that)
                name: x.name,
                title: x.title,
                description: x.description,
                status: x.status,
                order: x.order - 1, //decrease the 'order' number (so it can go up (smallest first))
                imageUrl: x.imageUrl,
            });

            //increase the 'order' number of the above employee (so it can go down)
            props.firebase.employee(rowAbove.id).set({ //for the employee in the above row (called 'rowAbove') we:
                //...rowAbove, //we keep all data
                name: rowAbove.name,
                title: rowAbove.title,
                description: rowAbove.description,
                status: rowAbove.status,
                order: orderOfRowAbove + 1, //increase the 'order' number (so it can go down (biggest last))
                imageUrl: rowAbove.imageUrl,
            });
        }
        //event.preventDefault();
    }


    const onDown = (x) => {
        //console.log("down");
        //console.log(x.order);
        if (x.order === (props.getReduxEmployeesArray.length)) {
            console.log("already the last");
        } else {
            //find the 'id' and 'order' number for the row below
            let orderOfRowBelow = x.order + 1; //the 'order' number of the row below is '+1' more (therefore we plus/add)
            let rowBelow = props.getReduxEmployeesArray[orderOfRowBelow - 1]; //then we get the complete data of the row above. We say '-1' because the array starts from '0'.
            //let idOfRowBelow = rowBelow.id; //get the id of the row below

            //increase the 'order' number of the targeted row ('employee') (so it can go down)
            props.firebase.employee(x.id).set({ //for the employee in the targeted row (called 'x') we:
                //...x, //we keep all data (NO! If I do this, it will also add the 'id' as a field - I don't want that)
                name: x.name,
                title: x.title,
                description: x.description,
                status: x.status,
                order: x.order + 1, //increase the 'order' number (so it can go down (biggest last))
                imageUrl: x.imageUrl,
            });

            //decrease the 'order' number of the below row ('employee') (so it can go op)
            props.firebase.employee(rowBelow.id).set({  //for the employee in the below row (called 'rowBelow') we:
                //...rowBelow, //we keep all data
                name: rowBelow.name,
                title: rowBelow.title,
                description: rowBelow.description,
                status: rowBelow.status,
                order: orderOfRowBelow - 1, //decrease the 'order' number (so it can go op (smallest first))
                imageUrl: rowBelow.imageUrl,
            });
        }
        //event.preventDefault();
    }

    const onOpenAddEmployeeModal = () => {
        props.setReduxShowAddEmployeeModal(true);
    }

    const onOpenDeleteEmployeeModal = (x) => {
        props.setReduxShowDeleteEmployeeModal(true);
        props.setReduxTargetedEmployee(x);
    }

    const onOpenEditEmployeeModal = (x) => {
        props.setReduxShowEditEmployeeModal(true);
        props.setReduxTargetedEmployee(x);
    }

    const { getReduxShowAddEmployeeModal, getReduxShowDeleteEmployeeModal, getReduxShowEditEmployeeModal } = props;


    return (
        <div>

            <div className="center-this">
                {values.loading && <div>Loading ...</div>} {/*if loading is defined then..*/}
            </div>

            <div className="tableArea">
                <button type="button" className="addButton font-this" onClick={() => onOpenAddEmployeeModal()}>+ Opret service</button>
            </div>

            {getReduxShowAddEmployeeModal === true ? (  //if 'getReduxShowAddEmployeeModal' is true, then display modal
                <AddEmployeeModalHook />
            ) : ( //else
                    null //do nothing
                )}

            {getReduxShowDeleteEmployeeModal === true ? (  //if 'getReduxShowDeleteEmployeeModal' is true, then display modal
                <DeleteEmployeeModalHook />
            ) : ( //else
                    null //do nothing
                )}

            {getReduxShowEditEmployeeModal === true ? (  //if 'getReduxShowEditEmployeeModal' is true, then display modal
                <EditEmployeeModalHook />
            ) : ( //else
                    null //do nothing
                )}



            <div>

                <table className="table-center marginBottomBig">
                    <thead>
                        <tr>
                            <th className="paddingLeft paddingRight">Nr.</th>
                            <th className="paddingLeft paddingRight">Navn</th>
                            <th className="paddingLeft paddingRight">Titel</th>
                            <th className="paddingLeft paddingRight">Beskrivelse</th>
                            <th className="paddingLeft paddingRight">Status</th>
                            <th className="paddingLeft paddingRight">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values.employeesArray && values.employeesArray.map(x => ( // if employeesArray is defined then map..
                            <tr key={x.id}>
                                <td className="center-this" >{x.order}</td>
                                <td className="paddingLeft paddingRight ellipsisTeamName" >{x.name}</td>
                                <td className="paddingLeft paddingRight ellipsisTeamTitle" >{x.title}</td>
                                <td className="paddingLeft paddingRight ellipsisTeamDescription" >{x.description}</td>
                                <td className="center-this paddingLeft paddingRight">
                                    {x.status === true ? (
                                        "Aktiv"
                                    ) : (
                                            "Inaktiv"
                                        )}
                                </td>
                                <td className="center-this paddingLeft paddingRight">
                                    <button type="button" className="table-button-padding" onClick={() => onUp(x)}>Op</button>
                                    <button type="button" className="table-button-padding" onClick={() => onDown(x)}>Ned</button>
                                    <button type="button" className="table-button-padding" onClick={() => onOpenEditEmployeeModal(x)} >Rediger</button>
                                    <button type="button" className="table-button-padding" onClick={() => onOpenDeleteEmployeeModal(x)} >Slet</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

//get
const mapStateToProps = (state) => ({
    getReduxEmployeesArray: state.teamState.employeesArray, //get the state from teamsState -> employeesArray
    getReduxShowAddEmployeeModal: state.teamState.showAddEmployeeModal,
    getReduxShowDeleteEmployeeModal: state.teamState.showDeleteEmployeeModal,
    getReduxShowEditEmployeeModal: state.teamState.showEditEmployeeModal,
    getReduxTargetedEmployee: state.teamState.targetedEmployee,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxEmployeesArray: (x) =>
        dispatch({ type: 'EMPLOYEES_SET', x}),

    setReduxShowAddEmployeeModal: (x) =>
        dispatch({ type: 'SHOW_ADD_EMPLOYEE_MODAL', x}),

    setReduxShowDeleteEmployeeModal: (x) =>
        dispatch({ type: 'SHOW_DELETE_EMPLOYEE_MODAL', x}),

    setReduxShowEditEmployeeModal: (x) =>
        dispatch({ type: 'SHOW_EDIT_EMPLOYEE_MODAL', x}),

    setReduxTargetedEmployee: (x) =>
        dispatch({ type: 'SET_TARGETED_EMPLOYEE', x}),

})

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(TeamFormHook);
