import React, { Component } from 'react'
import * as ROUTES from '../../constants/routes';
import PropTypes from 'prop-types';

class NavigationNonAuth extends Component {

    render() {
        return (
            <div className="nav" id="navbar" >
                <div className="normal-nav hide-small" id="normal-nav">{/*<!-- <div className="bar"> -->*/}
                    {/* <!-- Normal Nav menu --> */}
                    {/* <Link to={ROUTES.LANDING}>Landing</Link> */}
                    <a href="/#home" className="nav-btn hide-small">Hjem</a>
                    <a href="/#aboutUs" className="nav-btn hide-small">Om Os</a>
                    <a href="/#prices" className="nav-btn hide-small">Priser</a>
                    <a href="/#team" className="nav-btn hide-small">Team</a>
                    <a href="/#contact" className="nav-btn hide-small">Kontakt</a>
                    <span className="right">
                        <a href={ROUTES.SIGN_IN} className="nav-btn hide-small">Login</a>
                    </span>
                </div>
                

                {/* <!-- Menu button (small-screen) --> */}
                <a className="nav-btn hide-medium hide-large right" /*href="javascript:void(0);"*/ onClick={this.props.toggleFunction} title="Toggle Navigation Menu">
                        <i className="fa fa-bars"></i>
                </a>
                {/* <!-- Small Nav menu (small screens) --> */}
                <div className="mini-nav hide-medium hide-large links-hide mini-nav-background" id="mini-nav">
                    <div>
                        <a href="/#home" className="mini-nav-btn" onClick={this.props.toggleFunction}>Hjem</a>
                    </div>
                    <div>
                        <a href="/#aboutUs" className="mini-nav-btn" onClick={this.props.toggleFunction}>Om Os</a>
                    </div>
                    <div>
                        <a href="/#prices" className="mini-nav-btn" onClick={this.props.toggleFunction}>Priser</a>
                    </div>
                    <div>
                        <a href="/#team" className="mini-nav-btn" onClick={this.props.toggleFunction}>Team</a>
                    </div>
                    <div>
                        <a href="/#contact" className="mini-nav-btn" onClick={this.props.toggleFunction}>Kontakt</a>
                    </div>
                    <div>
                        <a href={ROUTES.SIGN_IN} className="mini-nav-btn" onClick={this.props.toggleFunction}>Login</a>
                    </div>
                </div>
            </div>
    
            // <div>
            //     <ul>
            //         <li>
            //             <Link to={ROUTES.LANDING}>Landing</Link>
            //         </li>
            //         <li>
            //             <Link to={ROUTES.SIGN_IN}>Sign In</Link>
            //         </li>
            //     </ul>
            // </div>
        )
    }
}

export default NavigationNonAuth


//PropTypes
NavigationNonAuth.propTypes = { //this component recieves the following props
    toggleFunction: PropTypes.func, //'toggleFunction' is a function
    authUser: PropTypes.object //'authUser' is an object
}