import React, { Component } from 'react'
import './HomeSection.css'

export class Home extends Component {
    render() {
        return (
            // <!-- First Parallax Image with Logo -->
            <div className="parallax-effect img-home opacity-75" id="home">
                {/* <!-- logo for big devices --> */}
                <div className="animate-opacity center hide-small">
                    <img className="logo-normal " src={require("../../../../images/logoGold5.png")} alt="logo"/>    
                </div>

                {/* <!-- logo for small devices --> */}
                <div className="animate-opacity center hide-medium hide-large">
                    <img className="logo-small" src={require("../../../../images/logoGold.png")}  alt="logo"/>
                </div>
            </div>
        )
    }
}

export default Home
