import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

//import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

/* To protect your routes from users who have no verified email address,
we will do it with a new higher-order component in src/components/Session/withEmailVerification.js
that has access to Firebase and the authenticated user */
const withEmailVerification = Component => { //HoC
  class WithEmailVerification extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isSent: false }; //local state to the higher-order component that tracks whether the button was clicked so we can prohibit from sending another verification email
    }

    onSendEmailVerification = () => {
        this.props.firebase.doSendEmailVerification()
            .then(() => this.setState({ isSent: true }));
    }
    
    render() {
      return (
        needsEmailVerification(this.props.authUser) ? (
            <div>
            {this.state.isSent ? (
              <p>
                E-Mail confirmation sent: Check you E-Mails (Spam
                folder included) for a confirmation E-Mail.
                Refresh this page once you confirmed your E-Mail.
              </p>
            ) : (
              <p>
                Verify your E-Mail: Check you E-Mails (Spam folder
                included) for a confirmation E-Mail or send
                another confirmation E-Mail.
              </p>
            )}
            <button
                type="button"
                onClick={this.onSendEmailVerification}
                disabled={this.state.isSent}
            >
                Send confirmation E-Mail
            </button>
            </div>
        ) : (
            <Component {...this.props} />
        )
      );
      // return (
      //   <AuthUserContext.Consumer>
      //     {authUser => 
      //       needsEmailVerification(authUser) ? (
      //           <div>
      //           {this.state.isSent ? (
      //             <p>
      //               E-Mail confirmation sent: Check you E-Mails (Spam
      //               folder included) for a confirmation E-Mail.
      //               Refresh this page once you confirmed your E-Mail.
      //             </p>
      //           ) : (
      //             <p>
      //               Verify your E-Mail: Check you E-Mails (Spam folder
      //               included) for a confirmation E-Mail or send
      //               another confirmation E-Mail.
      //             </p>
      //           )}
      //           <button
      //               type="button"
      //               onClick={this.onSendEmailVerification}
      //               disabled={this.state.isSent}
      //           >
      //               Send confirmation E-Mail
      //           </button>
      //           </div>
      //       ) : (
      //           <Component {...this.props} />
      //       )
      //     }
      //   </AuthUserContext.Consumer>
      // );
    }
  }

  /* mapStateToProps is used for selecting the part of the data from the store that the connected component needs.

    - It is called every time the store state changes.
    - It receives the entire store state, and should return an object of data this component needs. */
  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser //get the state from sessionStatate -> authuser
  });

  //return withFirebase(WithEmailVerification);
  return compose(
    withFirebase,
    connect(mapStateToProps)
  )(WithEmailVerification);
};

/* A function that checks if the authenticated user has a verified email and
an email/password sign in on associated with it. */
const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');


export default withEmailVerification;