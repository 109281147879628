import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';
//import useForm from './useForm';

const ContactFormHook = (props) => {

    // Declare state variables, which we'll call "inputValues"
    const [inputValues, setInputValues] = useState({ //'inputValues' used to get the data  &  'setInputValues' used to set the data
        loading: false,
        address: '',
        phone: '',
        email: '',
        openHours: '',
    })

    // Declare state variables for errors
    const [errors, setErrors] = useState({
        address: '',
        phone: '',
        email: '',
        openHours: '',
    })


    useEffect(() => { // Similar to componentDidMount and componentDidUpdate:
        onListenForData();

        return () => { // Specify how to clean up after this effect: //remove the listener to avoid memory leaks

        };

    }, []);


    //(using Cloud Firestore)
    const onListenForData = () => {
        setInputValues({
            ...inputValues, //we leave the states as they are
            loading: true, //and update only this
        })

        const unsubscribe =
            props.firebase
                .contact('data') //'data' is my id in the db
                .onSnapshot(snapshot => {

                    props.setReduxAddress(snapshot.data().address);
                    props.setReduxPhone(snapshot.data().phone);
                    props.setReduxEmail(snapshot.data().email);
                    props.setReduxOpenHours(snapshot.data().openHours);

                    setInputValues({
                        //...inputValues,
                        loading: false,
                        address: snapshot.data().address,
                        phone: snapshot.data().phone,
                        email: snapshot.data().email,
                        openHours: snapshot.data().openHours,
                    })

                });
    }


    const onChange = event => {
        setInputValues({
            ...inputValues, //we leave the states as they are
            [event.target.name]: event.target.value //and update only this
            //ex. username      :   legolas
        })

        setErrors({
            ...errors,
            [event.target.name]: ''
        })
        props.setReduxIsSaveSuccess(false); //to remove the 'save success' message if displayed
    };


    const onSave = (event) => {
        let isValid = validate(); //handle errors

        if (isValid) { //if 'isValid' is 'true'
            // console.log(true);
            try {
                props.firebase.contact('data').set({
                    address: inputValues.address, //update the 'address' in db
                    phone: inputValues.phone,
                    email: inputValues.email,
                    openHours: inputValues.openHours,
                })

                props.setReduxIsSaveSuccess(true); //to display 'save success' message
            } catch (error) {
                console.log(error)
            }
        }
        event.preventDefault(); //(prevent a browser reload/refresh) the preventDefault() method on the event prevents a reload of the browser which otherwise would be a natural behavior when using a submit in a form. 
    };


    const validate = () => {
        // console.log("validate")
        let isValid = true;
        let emailRegex = /\S+@\S+\.\S+/;

        let allErrors = {};

        if (!inputValues.address) {
            /*setErrors({
                ...errors,
                address: "Adresse er påkrævet",
            });*/
            allErrors.address = "Adresse er påkrævet";
            isValid = false;
        } else if (inputValues.address.length < 5) {
            /*setErrors({
                ...errors,
                address: "Adresse skal mindst have 5 tegn",
            });*/
            allErrors.address = "Adresse skal mindst have 5 tegn";
            isValid = false;
        }

        if (!inputValues.phone) {
            /*setErrors({
                ...errors,
                phone: "Telefon er påkrævet",
            });*/
            allErrors.phone = "Telefon er påkrævet";
            isValid = false;
        } else if (inputValues.phone.length < 8) {
            /*setErrors({
                ...errors,
                phone: "Telefon skal mindst have 8 tegn",
            });*/
            allErrors.phone = "Telefon skal mindst have 8 tegn";
            isValid = false;
        }

        if (!inputValues.email) {
            /*setErrors({
                ...errors,
                email: "Email er påkrævet",
            });*/
            allErrors.email = "Email er påkrævet";
            isValid = false;
            // console.log("Email er påkrævet")
        } else if (!emailRegex.test(inputValues.email)) { //if entered email doesn't comply with regex, then it's not an email
            /*setErrors({
                ...errors,
                email: "Email er ugyldig",
            });*/
            allErrors.email = "Email er ugyldig";
            isValid = false;
            // console.log("Email er ugyldig")
        }

        if (!inputValues.openHours) {
            /*setErrors({
                ...errors,
                openHours: "Åbningstider er påkrævet",
            });*/
            allErrors.openHours = "Åbningstider er påkrævet";
            isValid = false;
        } else if (inputValues.openHours.length < 5) {
            /*setErrors({
                ...errors,
                openHours: "Åbningstider skal mindst have 5 tegn",
            });*/
            allErrors.openHours = "Åbningstider skal mindst have 5 tegn";
            isValid = false;
        }

        setErrors({
            //...errors,
            address: allErrors.address,
            phone: allErrors.phone,
            email: allErrors.email,
            openHours: allErrors.openHours,
        });


        return isValid;
    }


    const { getReduxAddress, getReduxPhone, getReduxEmail, getReduxOpenHours, getReduxIsSaveSuccess } = props;


    const isInvalid =
        /*inputValues.address === '' || //if fields is empty, then 'isInvalid' is true
        inputValues.phone === '' ||
        inputValues.email === '' ||
        inputValues.openHours === '' ||*/
        inputValues.address === getReduxAddress && //if 'address' is equals 'getReduxAddress', then 'isInvalid' is true
        inputValues.phone === getReduxPhone &&
        inputValues.email === getReduxEmail &&
        inputValues.openHours === getReduxOpenHours;

    // console.log("D: " + inputValues.address);

    return (
        <div /*className="center-this"*/>

            {inputValues.loading && <div className="center-this">Loading ...</div>}

            <form onSubmit={event => onSave(event)} noValidate> {/* We disable the default form validation (ex. in the email input field, it will no longer validate if theres actually an email or not). We do this because we want to validate the data ourselves. */}

                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Adresse:</label>
                    </div>

                    <input className={`inputStyling ${errors.address && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell

                        name="address"
                        value={inputValues.address}
                        type="text"
                        onChange={onChange}
                    />
                </div>
                {errors.address &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.address}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">
                    <div className="labelStyling">
                        <label className="flex flexEnd">Telefon:</label>
                    </div>

                    <input className={`inputStyling ${errors.phone && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="phone"
                        value={inputValues.phone}
                        type="text"
                        onChange={onChange}
                    />
                </div>
                {errors.phone &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.phone}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">
                    <div className="labelStyling">
                        <label className="flex flexEnd">E-mail:</label>
                    </div>

                    <input className={`inputStyling ${errors.email && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="email"
                        value={inputValues.email}
                        type="email"
                        onChange={onChange}
                    />
                </div>
                {errors.email &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.email}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">
                    <div className="labelStyling">
                        <label className="flex flexEnd">Åbningstider:</label>
                    </div>
                    <textarea className={`inputStyling ${errors.openHours && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="openHours"
                        value={inputValues.openHours}
                        type="text"
                        onChange={onChange}
                        rows="7"
                    //cols="20"
                    />
                </div>
                {errors.openHours &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.openHours}</p>
                    </div>
                }

                <div className="center-this">
                    <button className="save-button-padding center-this" type="submit" disabled={isInvalid} >Gem</button> {/* Button is disabled if 'isInvalid' is true */}
                    
                    <br></br>

                    {getReduxIsSaveSuccess &&
                        <label className="inputStyling sucessMessage">Gemt successfuldt</label>
                    }
                </div>

            </form>

        </div>
    )
}

//get
const mapStateToProps = (state) => ({
    getReduxAddress: state.contactState.address,
    getReduxPhone: state.contactState.phone,
    getReduxEmail: state.contactState.email,
    getReduxOpenHours: state.contactState.openHours,
    getReduxIsSaveSuccess: state.contactState.isSaveSuccess,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxAddress: (x) =>
        dispatch({ type: 'ADDRESS_SET', x }),

    setReduxPhone: (x) =>
        dispatch({ type: 'PHONE_SET', x }),

    setReduxEmail: (x) =>
        dispatch({ type: 'EMAIL_SET', x }),

    setReduxOpenHours: (x) =>
        dispatch({ type: 'OPENHOURS_SET', x }),

    setReduxIsSaveSuccess: (x) =>
        dispatch({ type: 'ISSAVESUCCESS_SET', x }),

});

//export default connect(mapStateToProps, mapDispatchToProps)(AboutUsForm)
export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(ContactFormHook);

//export default ContactForm2
