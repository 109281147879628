import React, { Component } from 'react'
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';
import './TeamSection.css'

class TeamSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            employeesArray: null,
            activeEmployeesCount: 0,
        };
    }

    componentDidMount() {
        this.onListenForData();
    }

    //(using Cloud Firestore)
    onListenForData() {
        this.setState({ loading: true });


        this.unsubscribe = this.props.firebase
            .employees()
            .orderBy('order', 'asc') //desc
            .onSnapshot(snapshot => {
                if(snapshot.size) { //if snapshot contains data

                    let employeesArray = [];

                    snapshot.forEach(doc => {
                        if (doc.data().status === true){
                            //for each data 'doc' (document - this is how it's referred in firebase db) that the 'snapshot' contains, which contains 'status' as 'true', we will push/insert it into the array using '...doc.data()' + we add an additional field called 'id' with the value of 'doc.id'
                            employeesArray.push({ ...doc.data(), id: doc.id })
                        }
                        
                    });

                    //console.log("employeesArray: "+employeesArray.length);

                    this.setState({
                        loading: false,
                        employeesArray: employeesArray,
                        activeEmployeesCount: employeesArray.length,
                    });
                    
                }else {
                    this.setState({ //we reset the state (because it could be, that we had some data, then we deleted them all, so we no longer have any. Therefore the state needs to be reset, so it doesen't contain anything either)
                        loading: false,
                        employeesArray: null,
                    });
                } 
            });
    }

    //remove the listener to avoid memory leaks
    componentWillUnmount() {
        this.unsubscribe(); //(using Cloud Firestore)
    }

    render() {
        const { loading, activeEmployeesCount, employeesArray } = this.state;

        return (
            
            // <!-- (team Section) -->
            <div className="team" id="team">
                <div className="team-content">
                    <div className="team-title">
                        Team
                    </div>

                    <div className="center-this">
                        {loading && <div>Loading ...</div>} {/*if loading is defined then..*/}
                    </div>

                    {(activeEmployeesCount === 1) ? (
                        <div className="team-container flex-wrap">
                            <div className="team-left-side hide-small">
                                {/* <img className="img-team" src={require("../../../../images/yf.jpg")} alt="profile"/> */}
                                <img className="img-team" src={employeesArray[0].imageUrl} alt="Image" height="435px" width="300px" />
                                <div className="white-borders"></div>
                                <img className="img-black-brush" src={require("../../../../images/blackBrush_smallInHeightv3.png")} alt="brush"/>
                                <div className="team-name-on-black-brush">
                                    {/* Yusuf */}
                                    {employeesArray[0].name}
                                    <div className="team-position">
                                        {/* Barber & Indehaver   */}
                                        {employeesArray[0].title}
                                    </div>
                                </div>
                                {/* <div className="overlay">
                                    <div className="person-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla nisl non egestas congue. In ac ultricies sapien, at accumsan erat. Phasellus sollicitudin, justo ac mattis varius, velit tortor finibus nisi, id malesuada justo diam non turpis. Sed non urna bibendum, tincidunt quam eget, gravida velit. Sed a auctor velit.
                                    </div>
                                </div> */}
                            </div>

                            <div className="team-left-side hide-medium hide-large team-left-side-mobile">
                                {/* <img className="img-team" src={require("../../../../images/yf.jpg")} alt="profile"/> */}
                                <img className="img-team" src={employeesArray[0].imageUrl} alt="Image" height="435px" width="300px" />
                                <div className="white-borders"></div>
                                <img className="img-black-brush" src={require("../../../../images/blackBrush_smallInHeightv3.png")} alt="brush"/>
                                <div className="team-name-on-black-brush">
                                    {/* Yusuf */}
                                    {employeesArray[0].name}
                                    <div className="team-position">
                                        {/* Barber & Indehaver   */}
                                        {employeesArray[0].title}
                                    </div>
                                </div>
                                {/* <div className="overlay">
                                    <div className="person-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla nisl non egestas congue. In ac ultricies sapien, at accumsan erat. Phasellus sollicitudin, justo ac mattis varius, velit tortor finibus nisi, id malesuada justo diam non turpis. Sed non urna bibendum, tincidunt quam eget, gravida velit. Sed a auctor velit.
                                    </div>
                                </div> */}
                            </div>
                            

                            <div className="team-right-side hide-small hide-medium hide-large"> {/* Im hiding this currently, as we don't want it */}
                                <div className="team-name">
                                    {/* Yusuf */}
                                    {employeesArray[0].name}
                                </div>
                                <div className="team-description">
                                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla nisl non egestas congue. In ac ultricies sapien, at accumsan erat. Phasellus sollicitudin, justo ac mattis varius, velit tortor finibus nisi, id malesuada justo diam non turpis. Sed non urna bibendum, tincidunt quam eget, gravida velit. Sed a auctor velit. */}
                                        {employeesArray[0].description}
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className="team-container flex-wrap"  >
                            {employeesArray && employeesArray.map(x => (
                            
                                <div className="team-left-side"  key={x.id}>
                                    {/* <img className="img-team" src={require("../../../../images/yf.jpg")} alt="profile"/> */}
                                    <img className="img-team" src={x.imageUrl} alt="Image" height="435px" width="300px" />
                                    <div className="white-borders"></div>
                                    <img className="img-black-brush" src={require("../../../../images/blackBrush_smallInHeightv3.png")} alt="brush"/>
                                    <div className="team-name-on-black-brush">
                                        {/* Yusuf */}
                                        {x.name}
                                        <div className="team-position">
                                            {/* Barber & Indehaver   */}
                                            {x.title}
                                        </div>
                                    </div>
                                    <div className="overlay">
                                        <div className="person-description hide-small hide-medium hide-large"> {/* Im hiding this currently, as we don't want it */}
                                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla nisl non egestas congue. In ac ultricies sapien, at accumsan erat. Phasellus sollicitudin, justo ac mattis varius, velit tortor finibus nisi, id malesuada justo diam non turpis. Sed non urna bibendum, tincidunt quam eget, gravida velit. Sed a auctor velit. */}
                                                {x.description}
                                        </div>
                                    </div>
                                </div>
                            
                            ))}
                        </div>
                    )}

                    {/* <div className="team-container flex-wrap">
                        <div className="team-left-side">
                            <img className="img-team" src={require("../../../../images/yf.jpg")} alt="profile"/>
                            <div className="white-borders"></div>
                            <img className="img-black-brush" src={require("../../../../images/blackBrush_smallInHeightv3.png")} alt="brush"/>
                            <div className="team-name-on-black-brush">
                                Yusuf
                                <div className="team-position">
                                    Barber & Indehaver  
                                </div>
                            </div>
                            <div className="overlay">
                                <div className="person-description">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla nisl non egestas congue. In ac ultricies sapien, at accumsan erat. Phasellus sollicitudin, justo ac mattis varius, velit tortor finibus nisi, id malesuada justo diam non turpis. Sed non urna bibendum, tincidunt quam eget, gravida velit. Sed a auctor velit.
                                </div>
                            </div>
                        </div>
                        

                        <div className="team-right-side">
                            <div className="team-name">
                                Yusuf
                            </div>
                            <div className="team-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla nisl non egestas congue. In ac ultricies sapien, at accumsan erat. Phasellus sollicitudin, justo ac mattis varius, velit tortor finibus nisi, id malesuada justo diam non turpis. Sed non urna bibendum, tincidunt quam eget, gravida velit. Sed a auctor velit.
                            </div>
                        </div>
                    </div> */}
                </div> 
            </div>
                
            // {/* <!-- If there will be more team members, then only add profile images with their name in the bottom.
            // When the user hover the mouse over an image, it will be darken 90% and some text description of the 
            // barber will be displayed. Maybe with a transitions (text moves from bottom to top i 0.2 seconds) --> */}

        )
    }
}

//export default TeamSection
export default compose(
    withFirebase
)(TeamSection);