import React, { Component } from 'react'
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';
import './ContactSection.css'

class ContactSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            address: '',
            phone: '',
            email: '',
            openHours: '',
        };
    }

    componentDidMount() {
        this.onListenForAboutUs();
    }

    onListenForAboutUs(){
        this.unsubscribe = this.props.firebase
            .contact('data') //'data' is my id in the db
            .onSnapshot(snapshot => {
                //this.props.setReduxAbout(snapshot.data().about);
                this.setState({
                    address: snapshot.data().address,
                    phone: snapshot.data().phone,
                    email: snapshot.data().email,
                    openHours: snapshot.data().openHours,
                });
            });
    }

    componentWillUnmount() {
        this.unsubscribe(); //(using Cloud Firestore)
    }

    render() {
        const {address, phone, email, openHours } = this.state;

        return (
                    
            // <!-- (contact Section) -->
            <div className="contact" id="contact">
                <div className="contact-content img-contact">
                    <div className="contact-title">
                        Kontakt
                    </div>

                    <div className="contact-container hide-small">
                        <div className="contact-info">
                            
                            <div className="info-data flex">
                                <div className="info-icon-area">
                                        <img className="info-icon" src={require("../../../../images/icons/map-marker.svg")} alt=""/>
                                </div>
                                <div className="info-details">
                                        <span>Adresse</span>
                                        {/* <p>Rosenkildevej 4A, 3000, Helsingør</p> */}
                                        <p>{address}</p>
                                </div>
                            </div>


                            <div className="info-data flex">
                                <div className="info-icon-area">
                                        <img className="info-icon" src={require("../../../../images/icons/phone-receiver.png")} alt=""/>
                                </div>
                                <div className="info-details">
                                        <span>Telefon</span>
                                        {/* <p>50 23 60 23</p> */}
                                        <p>{phone}</p>
                                </div>
                            </div>

                            <div className="info-data flex">
                                    <div className="info-icon-area">
                                            <img className="info-icon" src={require("../../../../images/icons/close-envelope.svg")} alt=""/>
                                    </div>
                                    <div className="info-details">
                                            <span>Email</span>
                                            {/* <p>royal?????</p> */}
                                            <p>{email}</p>
                                    </div>
                                </div>
            
            
                                <div className="info-data flex">
                                    <div className="info-icon-area">
                                            <img className="info-icon" src={require("../../../../images/icons/clock-circular-outline.svg")} alt=""/>
                                    </div>
                                    <div className="info-details">
                                            <span>Åbningstider</span>
                                            {/* <p>Man-Fre: 09.00 - 17.30</p>
                                            <p>Lør: 09.00 - 15.00</p>
                                            <p>Søn: Lukket</p> */}
                                            <p  className="renderNewLine">{openHours}</p>
                                    </div>
                                </div>
                        </div>


                        
                        <div className="contact-location">
                                {/* <div style="width: 100%">
                                    <iframe width="100%" height="400" src={require("https://maps.google.com/maps?width=100%&amp;height=400&amp;hl=en&amp;q=Rosenkildevej%204A%203000%20Helsing%C3%B8r+(Royal%20Cut)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=A&amp;output=embed")} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                        <a href={"https://www.maps.ie/map-my-route/"}>Map a route</a>
                                    </iframe>
                                </div> */}
                                <br />
                            {/* <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48331.30547831374!2d-74.05590848053964!3d40.79046415662649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25804d4293b57%3A0x5efe2629bb9f9381!2sNorth+Bergen%2C+NJ%2C+USA!5e0!3m2!1sen!2sin!4v1503222998458" frameborder="0" allowfullscreen=""></iframe> --> */}
                        </div>
                    </div>


                    <div className="contact-container hide-medium hide-large">
                        <div className="contact-info">
                            
                            <div className="info-data flex">
                                <div className="info-icon-area">
                                        <img className="info-icon-mobile" src={require("../../../../images/icons/map-marker.svg")} alt=""/>
                                </div>
                                <div className="info-details-mobile">
                                        <span>Adresse</span>
                                        {/* <p>Rosenkildevej 4A, 3000, Helsingør</p> */}
                                        <p>{address}</p>
                                </div>
                            </div>


                            <div className="info-data flex">
                                <div className="info-icon-area">
                                        <img className="info-icon-mobile" src={require("../../../../images/icons/phone-receiver.png")} alt=""/>
                                </div>
                                <div className="info-details-mobile">
                                        <span>Telefon</span>
                                        {/* <p>50 23 60 23</p> */}
                                        <p>{phone}</p>
                                </div>
                            </div>

                            <div className="info-data flex">
                                    <div className="info-icon-area">
                                            <img className="info-icon-mobile" src={require("../../../../images/icons/close-envelope.svg")} alt=""/>
                                    </div>
                                    <div className="info-details-mobile">
                                            <span>Email</span>
                                            {/* <p>royal?????</p> */}
                                            <p>{email}</p>
                                    </div>
                                </div>
            
            
                                <div className="info-data flex">
                                    <div className="info-icon-area">
                                            <img className="info-icon-mobile" src={require("../../../../images/icons/clock-circular-outline.svg")} alt=""/>
                                    </div>
                                    <div className="info-details-mobile">
                                            <span>Åbningstider</span>
                                            {/* <p>Man-Fre: 09.00 - 17.30</p>
                                            <p>Lør: 09.00 - 15.00</p>
                                            <p>Søn: Lukket</p> */}
                                            <p  className="renderNewLine">{openHours}</p>
                                    </div>
                                </div>
                        </div>


                        
                        <div className="contact-location">
                                {/* <div style="width: 100%">
                                    <iframe width="100%" height="400" src={require("https://maps.google.com/maps?width=100%&amp;height=400&amp;hl=en&amp;q=Rosenkildevej%204A%203000%20Helsing%C3%B8r+(Royal%20Cut)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=A&amp;output=embed")} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                        <a href={"https://www.maps.ie/map-my-route/"}>Map a route</a>
                                    </iframe>
                                </div> */}
                                <br />
                            {/* <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48331.30547831374!2d-74.05590848053964!3d40.79046415662649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25804d4293b57%3A0x5efe2629bb9f9381!2sNorth+Bergen%2C+NJ%2C+USA!5e0!3m2!1sen!2sin!4v1503222998458" frameborder="0" allowfullscreen=""></iframe> --> */}
                        </div>
                    </div>



                </div>

            </div>

        )
    }
}

//export default ContactSection
export default compose(
    withFirebase
)(ContactSection)
