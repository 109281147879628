import React from 'react';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';


const SignOutButton = ({ firebase }) => (
  <a href={ROUTES.HOME} onClick={firebase.doSignOut} className="nav-btn hide-small">Sign Out</a>
);

export default withFirebase(SignOutButton);