import React from 'react'
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../../../Session';
import AdminAreaNav from '../../AdminAreaNav';
//import PricesForm from '../AdminPrices/PricesForm'
import PricesFormHook from '../AdminPrices/PricesFormHook'

const AdminPricesHook = () => {
    return (
        <div>
            <AdminAreaNav />
            <h3 className="admin-title">Priser (Admin)</h3>
            <PricesFormHook />
        </div>
    )
}

const condition = authUser => !!authUser;

//export default AdminPrices
export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(AdminPricesHook);