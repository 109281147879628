//Our Firebase class is the glue between our React application and the Firebase API.

import firebaseApp from 'firebase/app';
import 'firebase/auth';
//import 'firebase/database'; //(using Realtime Database)
import 'firebase/firestore'; //(using Cloud Firestore)
import 'firebase/storage';

/* File for the Firebase setup.
We will use a JavaScript class to encapsulate all Firebase functionalities, realtime database, and authentication,
as a well-defined API for the rest of the application. You need only instantiate the class once, after which it can
use it then to interact with the Firebase API, your custom Firebase interface. */

/* We use environment variables, but we have to use the REACT_APP prefix when we use
create-react-app to set up the application: */

/* you can create a second Firebase project on the Firebase website to have one project for your development
environment and one project for your production environment. That way, you never mix data in the Firebase
database in development mode with data from your deployed application (production mode). If you decide to 
create projects for both environments, use the two configuration objects in your Firebase setup and decide which
one you take depending on the development/production environment */

const devFirebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

const prodFirebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const firebaseConfig = process.env.NODE_ENV === 'production' ? prodFirebaseConfig : devFirebaseConfig



class Firebase {
    constructor() {
        firebaseApp.initializeApp(firebaseConfig); //initialize firebase with the configuration

        //this.serverValue = firebaseApp.database.ServerValue; //(using Realtime Database) //When using Firebase, it's best not to choose the date yourself, but let Firebase choose it depending on their internal mechanics. The server value constants from Firebase can be made available in the Firebase class
        this.fieldValue = firebaseApp.firestore.FieldValue; //(using Cloud Firestore)

        //we implement the authentication & database API for our Firebase class
        this.firebaseAuth = firebaseApp.auth(); //instantiate the firebase auth package
        //this.firebaseRealtimeDb = firebaseApp.database(); //(using Realtime Database) //initialize the realtime database API for your Firebase class 
        this.firestoreDb = firebaseApp.firestore(); //instantiate Cloud Firestore (using Cloud Firestore)

        this.storage = firebaseApp.storage(); //instantiate firebase storage
    }




    /* Let's define all the authentication functions as class methods step by step.
    They will serve our communication channel from the Firebase class to the Firebase API.*/

    /*****************************************************************
     ********* Authentication API (authentication interface) *********
     ****************************************************************/

    // sign up function (registration)
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.firebaseAuth.createUserWithEmailAndPassword(email, password); //call the Firebase 'createUserWithEmailAndPassword' to create a user

    // login/sign-in function
    doSignInWithEmailAndPassword = (email, password) =>
        this.firebaseAuth.signInWithEmailAndPassword(email, password);

    // Sign out function (Firebase knows about the currently authenticated user. If no user is authenticated, nothing will happen when this function is called)
    doSignOut = () => 
        this.firebaseAuth.signOut();

    // Reset password for an authenticated user
    doPasswordReset = (email) =>
        this.firebaseAuth.sendPasswordResetEmail(email);

    // Change password for an authenticated user
    doPasswordUpdate = password =>
        this.firebaseAuth.currentUser.updatePassword(password);
    

    // send a verification email
    doSendEmailVerification = () =>
        this.firebaseAuth.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
        });


    /*****************************************************************
     ******************* User API (User interface) *******************
     ****************************************************************/

     //we create a function, that makes use of the firebase function 'onAuthStateChanged()', so we can call it from other components
    onAuthUserListener = (next, fallback) =>
        /* The 'onAuthStateChanged()' firebase function is called everytime something changes for the authenticated user.
        It is called when a user signs up, signs in, and signs out. If a user signs out, the authUser object becomes null,
        so the authUser property in the local state is set to null and all components depending on it adjust their behavior. */
        this.firebaseAuth.onAuthStateChanged(authUser => {
            if (authUser) { //if user is not null

                this.manageUserSession(authUser);
                
                /*console.log(this.firebaseAuth)
                console.log(this.firebaseAuth.currentUser.Sb.metadata.lastSignInTime)
                console.log(new Date().getTime() / 1000);*/
                
                /* We are using the users reference from our Firebase class to
                attach a listener. The listener is called once(). The once() method
                registers a listener that would be called only once.  */

                /* A DataSnapshot contains data from a Database location.

                Any time you read data from the Database, you receive the data as a DataSnapshot.
                A DataSnapshot is passed to the event callbacks you attach with on() or once().
                You can extract the contents of the snapshot as a JavaScript object by calling the val() method.
                Alternatively, you can traverse into the snapshot by calling child() to return child snapshots
                (which you could then call val() on). */
                this.user(authUser.uid)
                //.once('value') //(using Realtime Database)
                .get() //(using Cloud Firestore)
                .then(snapshot => {
                    //const dbUser = snapshot.val(); //(using Realtime Database)
                    const dbUser = snapshot.data(); //(using Cloud Firestore)

                        //default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }
                        
                        //merge auth and db user
                        //we merge everything from the database user with the unique identifier and email from the authenticated user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,

                            //To find out if a user has a verified email, you can retrieve this information from the authenticated user
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            
                            ...dbUser //add the rest from dbUser (You may need more properties from the authenticated user later, but at this point the unique identifier and the email are sufficient.)
                        };

                        next(authUser); //implement the specific implementation details of every higher-order component (local state for authentication, redirect for authorization)
                    });
            } else {
                fallback(); //implement the specific implementation details of every higher-order component (local state for authentication, redirect for authorization)
            }
        });


        manageUserSession(authUser){ //we manage the user login session to last 1hour, then the user will automatically be signed out (if we don't do this, the user will keep being signed in).
            let userSessionTimeout = null;

            authUser.getIdTokenResult().then((idTokenResult) => { //'idTokenResult' is data in json format
                const authTime = idTokenResult.claims.auth_time * 1000;
                const sessionDurationInMilliseconds = 60 * 60 * 1000; // 60 min //we set the session to be 60 min
                const expirationInMilliseconds = sessionDurationInMilliseconds - (Date.now() - authTime); //the remaining time
                userSessionTimeout = setTimeout(() => this.firebaseAuth.signOut(), expirationInMilliseconds); //'setTimeout()' is a function, that will be executed after the timer expires
                /* The function 'setTimeout()' delays the execution of our anonymous function '() => this.firebaseAuth.signOut()' for the given time in milliseconds.
                Further details here (https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout).
                Our variable  'expirationInMilliseconds'  defines how many milliseconds of the 1 hour are left since the user signed in.
                Because if the user signs in and reloads the page after 35 minutes we want to delay the call of the function  'this.firebaseAuth.signOut()'  for only 25 minutes instead of 60 */


                /*console.log("getIdTokenResult: " + authUser.getIdTokenResult())
                console.log("authTime (last sign in time): " + authTime)
                console.log("sessionDurationInMilliseconds: " + sessionDurationInMilliseconds)
                console.log("expirationInMilliseconds (the remaining time before user will be signed out): " + expirationInMilliseconds)
                console.log("userSessionTimeout: " + userSessionTimeout)*/
            });
        }



    /*****************************************************************
     ******************* User API (User interface) *******************
     ****************************************************************/

     //user = uid => this.firebaseRealtimeDb.ref(`users/${uid}`); //(using Realtime Database) //get a specific user by id
     //users = () => this.firebaseRealtimeDb.ref('users'); //(using Realtime Database) //get all users
     /* The paths in the ref() method match the location where your entities (users) will be
     stored in Firebase's realtime database API. If you delete a user at "users/5", the user
     with the identifier 5 will be removed from the database. If you create a new user at "users",
     Firebase creates the identifier for you and assigns all the information you pass for the user. 
     
    Template literals:
        Template literals are string literals allowing embedded expressions.
        Template literals are enclosed by the back-tick (` `)  character instead
        of double or single quotes. Template literals can contain placeholders.
        These are indicated by the dollar sign and curly braces (${expression}).
     */

    user = uid => this.firestoreDb.doc(`users/${uid}`); //(using Cloud Firestore)
    users = () => this.firestoreDb.collection('users'); //(using Cloud Firestore)

    /*****************************************************************
     **************** Message API (Message interface) ****************
     ****************************************************************/

    //message = uid => this.firebaseRealtimeDb.ref(`messages/${uid}`); //(using Realtime Database) //get a specific message by id
    //messages = () => this.firebaseRealtimeDb.ref('messages'); //(using Realtime Database) //get all messages

    message = uid => this.firestoreDb.doc(`messages/${uid}`); //(using Cloud Firestore)
    messages = () => this.firestoreDb.collection('messages'); //(using Cloud Firestore)




    /*****************************************************************
     **************** AboutUs API (AboutUs interface) ****************
     ****************************************************************/
    
    aboutUs = (id) => this.firestoreDb.doc(`aboutUs/${id}`); //(using Cloud Firestore)

    /*****************************************************************
     **************** Prices API (Prices interface) ****************
     ****************************************************************/
    service = id => this.firestoreDb.doc(`services/${id}`); //(using Cloud Firestore)
    services = () => this.firestoreDb.collection('services'); //(using Cloud Firestore)

    /*****************************************************************
     **************** Contact API (Contact interface) ****************
     ****************************************************************/
    
    contact = (id) => this.firestoreDb.doc(`contact/${id}`); //(using Cloud Firestore)

    /*****************************************************************
     **************** Employees API (Employees interface) ****************
     ****************************************************************/
    employee = id => this.firestoreDb.doc(`employees/${id}`); //(using Cloud Firestore)
    employees = () => this.firestoreDb.collection('employees'); //(using Cloud Firestore)

}

export default Firebase;






