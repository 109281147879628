import React, { Component } from 'react'
import HomeSection from './sections/HomeSection/HomeSection'
import AboutSection from './sections/AboutSection/AboutSection'
import PriceSection from './sections/PriceSection/PriceSection'
import TeamSection from './sections/TeamSection/TeamSection'
import ContactSection from './sections/ContactSection/ContactSection'
import FooterSection from './sections/Footer/Footer'

class OnePageApplication extends Component {


    render() {
        return (
            <div className="overflow">
                <React.Fragment>
                    <HomeSection/>
                    <AboutSection/>
                    <PriceSection/>
                    <TeamSection/>
                    <ContactSection/>
                    <FooterSection/>
                </React.Fragment>
            </div>
        )
    }
}

export default OnePageApplication
