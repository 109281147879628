import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';
import './PriceSection.css'

class PriceSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            servicesArray: null
        };
    }

    componentDidMount() {
        this.onListenForData();
    }

    //(using Cloud Firestore)
    onListenForData() {
        this.setState({ loading: true });


        this.unsubscribe = this.props.firebase
            .services()
            .orderBy('order', 'asc') //desc
            .onSnapshot(snapshot => {
                if (snapshot.size) { //if snapshot contains data

                    let servicesArray = [];

                    snapshot.forEach(doc =>
                        //for each data 'doc' (document - this is how it's referred in firebase db) that the 'snapshot' contains, we will push/insert it into the array using '...doc.data()' + we add an additional field called 'id' with the value of 'doc.id'
                        servicesArray.push({ ...doc.data(), id: doc.id }),
                    );

                    this.setState({
                        loading: false,
                        servicesArray: servicesArray,
                    });

                }
            });
    }

    //remove the listener to avoid memory leaks
    componentWillUnmount() {
        this.unsubscribe(); //(using Cloud Firestore)
    }

    render() {
        const { loading, servicesArray } = this.state;

        // console.log(servicesArray);

        return (
            <div className="prices" id="prices">
                <div className="prices-content img-prices">
                    <div className="price-title">
                        Priser
                    </div>


                    <div className="center-this">
                        {loading && <div>Loading ...</div>} {/*if loading is defined then..*/}
                    </div>


                    <div className="centerServices flex flexWrap hide-small">


                        {servicesArray && servicesArray.map(x => (

                            <div className="price-box" key={x.id}>
                                <div className="price-box-inner flex">
                                    <div className="price-box-left flex">



                                        {(
                                            //function() { //using a self invoking JavaScript function
                                            () => { //using ES6
                                                switch (x.iconNr) {
                                                    case '1':
                                                        return <div className="box-icon">
                                                            <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon" />
                                                        </div>
                                                    case '2':
                                                        return <div className="box-icon">
                                                            <img className="price-icon" src={require("../../../../images/icons/razor-hair-salon-tool.svg")} alt="icon" />
                                                            <img className="price-icon" src={require("../../../../images/icons/beard.svg")} alt="icon" />
                                                        </div>
                                                    case '3':
                                                        return <div className="box-icon">
                                                            <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon" />
                                                            <img className="price-icon-small" src={require("../../../../images/icons/beard.svg")} alt="icon" />
                                                        </div>
                                                    case '4':
                                                        return <div className="box-icon">
                                                            <img className="price-icon-big" src={require("../../../../images/icons/scissor-and-comb.svg")} alt="icon" />
                                                        </div>
                                                    case '5':
                                                        return <div className="box-icon">
                                                            <img className="price-icon" src={require("../../../../images/icons/shaver.svg")} alt="icon" />
                                                        </div>
                                                    case '6':
                                                        return <div className="box-icon">
                                                            <img className="price-icon" src={require("../../../../images/icons/wax.svg")} alt="icon" />
                                                        </div>
                                                    case '7':
                                                        return <div className="box-icon">
                                                            <img className="price-icon" src={require("../../../../images/icons/clock-circular-outline.svg")} alt="icon" />
                                                        </div>
                                                    default:
                                                        return null;
                                                }
                                            }
                                        )()}




                                        <div className="box-text">
                                            <div className="service-title">
                                                {x.name} {/* displays the name */}
                                            </div>
                                            <div className="service-description">
                                                {x.description} {/* displays the description */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price-box-right">
                                        <div className="price-text">Pris</div>
                                        <div className="price-value">{x.price} {/* displays the price */}</div>
                                    </div>
                                </div>
                            </div>

                        ))}



                        {/* <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Herre- eller børneklip
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">150,-</div>
                                </div>
                            </div>
                        </div>
                

                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon" src={require("../../../../images/icons/razor-hair-salon-tool.svg")} alt="icon"/>
                                        <img className="price-icon" src={require("../../../../images/icons/beard.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Gammeldags Barbering
                                        </div>
                                        <div className="service-description">
                                            Lorem Ipsum is simply dummy text of the printorem Ipsum is simply mmy text of the printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">70,-</div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon"/>
                                        <img className="price-icon-small" src={require("../../../../images/icons/beard.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Klip & Barbering
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">200,-</div>
                                </div>
                            </div>
                        </div>
                    
            
                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon-big" src={require("../../../../images/icons/scissor-and-comb.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Klip m. saks
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris fra</div>
                                    <div className="price-value">150,-</div>
                                </div>
                            </div>
                        </div>


                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="timing-icon" src={require("../../../../images/icons/shaver.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Trimning af skæg
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">30,-</div>
                                </div>
                            </div>
                        </div>
                    
            
                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="timing-icon" src={require("../../../../images/icons/wax.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Black-Mask/Voks behandling
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">50,-</div>
                                </div>
                            </div>
                        </div>


                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="timing-icon" src={require("../../../../images/icons/clock-circular-outline.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Tidsbestilling
                                        </div>
                                        <div className="service-description">
                                            Hvis ønsker at pritere sin tids blablabla (det yf har skrevet på tavlen)
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">50,-</div>
                                </div>
                            </div>
                        </div> */}


                    </div>


                    <div className="centerServices flex flexWrap hide-medium hide-large">


                        {servicesArray && servicesArray.map(x => (

                            <div className="price-box-mobile" key={x.id}>
                                <div className="price-box-inner flex">
                                    <div className="price-box-left-mobile flex">



                                        {(
                                            //function() { //using a self invoking JavaScript function
                                            () => { //using ES6
                                                switch (x.iconNr) {
                                                    case '1':
                                                        return <div className="box-icon-mobile">
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon" />
                                                        </div>
                                                    case '2':
                                                        return <div className="box-icon-mobile">
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/razor-hair-salon-tool.svg")} alt="icon" />
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/beard.svg")} alt="icon" />
                                                        </div>
                                                    case '3':
                                                        return <div className="box-icon-mobile">
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon" />
                                                            <img className="price-icon-small-mobile" src={require("../../../../images/icons/beard.svg")} alt="icon" />
                                                        </div>
                                                    case '4':
                                                        return <div className="box-icon-mobile">
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/scissor-and-comb.svg")} alt="icon" />
                                                        </div>
                                                    case '5':
                                                        return <div className="box-icon-mobile">
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/shaver.svg")} alt="icon" />
                                                        </div>
                                                    case '6':
                                                        return <div className="box-icon-mobile">
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/wax.svg")} alt="icon" />
                                                        </div>
                                                    case '7':
                                                        return <div className="box-icon-mobile">
                                                            <img className="price-icon-mobile" src={require("../../../../images/icons/clock-circular-outline.svg")} alt="icon" />
                                                        </div>
                                                    default:
                                                        return null;
                                                }
                                            }
                                        )()}




                                        <div className="box-text">
                                            <div className="service-title">
                                                {x.name} {/* displays the name */}
                                            </div>
                                            <div className="service-description">
                                                {x.description} {/* displays the description */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price-box-right-mobile">
                                        <div className="price-text-mobile">Pris</div>
                                        <div className="price-value-mobile">{x.price} {/* displays the price */}</div>
                                    </div>
                                </div>
                            </div>

                        ))}

                    </div>



                    {/* <div className="prices-container flex-wrap">



                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Herre- eller børneklip
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">150,-</div>
                                </div>
                            </div>
                        </div>
                

                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon" src={require("../../../../images/icons/razor-hair-salon-tool.svg")} alt="icon"/>
                                        <img className="price-icon" src={require("../../../../images/icons/beard.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Gammeldags Barbering
                                        </div>
                                        <div className="service-description">
                                            Lorem Ipsum is simply dummy text of the printorem Ipsum is simply mmy text of the printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">70,-</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="prices-container flex-wrap">

                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon"/>
                                        <img className="price-icon-small" src={require("../../../../images/icons/beard.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Klip & Barbering
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">200,-</div>
                                </div>
                            </div>
                        </div>
                    
            
                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="price-icon-big" src={require("../../../../images/icons/scissor-and-comb.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Klip m. saks
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris fra</div>
                                    <div className="price-value">150,-</div>
                                </div>
                            </div>
                        </div>

                    </div>




                    <div className="prices-container flex-wrap">

                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="timing-icon" src={require("../../../../images/icons/shaver.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Trimning af skæg
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">30,-</div>
                                </div>
                            </div>
                        </div>
                    
            
                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="timing-icon" src={require("../../../../images/icons/wax.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Black-Mask/Voks behandling
                                        </div>
                                        <div className="service-description">
                                            printing and typing.
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">50,-</div>
                                </div>
                            </div>
                        </div>

                    </div>







                    <div className="prices-container flex-wrap">
                    
            
                        <div className="price-box">
                            <div className="price-box-inner flex">
                                <div className="price-box-left flex">
                                    <div className="box-icon">
                                        <img className="timing-icon" src={require("../../../../images/icons/clock-circular-outline.svg")} alt="icon"/>
                                    </div>
                                    <div className="box-text">
                                        <div className="service-title">
                                            Tidsbestilling
                                        </div>
                                        <div className="service-description">
                                            Hvis ønsker at pritere sin tids blablabla (det yf har skrevet på tavlen)
                                        </div>
                                    </div>
                                </div>
                                <div className="price-box-right">
                                    <div className="price-text">Pris</div>
                                    <div className="price-value">50,-</div>
                                </div>
                            </div>
                        </div>

                    </div> */}





                </div>

            </div>
        )
    }
}



export default compose(
    withFirebase
)(PriceSection);