import React, { Component } from 'react'
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../../../Session';
import AdminAreaNav from '../../AdminAreaNav';
//import ContactForm from './ContactForm'
import ContactFormHook from './ContactFormHook'

class AdminContact extends Component {
    render() {
        return (
            <div>
                <AdminAreaNav />
                <h3 className="admin-title">Kontakt (Admin)</h3>
                <ContactFormHook />
            </div>
        )
    }
}

const condition = authUser => !!authUser;

//export default AdminContact
export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(AdminContact);