import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import Firebase, {FirebaseContext} from './components/Firebase'; //provide a Firebase instance to our entire application

//redux stuff
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
    <Provider store={store}> {/* connect your state layer with your view layer. The Redux store can be provided for the component hierarchy using Redux's Provider component. This time, the Provider component from the Redux library passes down the whole store */}
        <FirebaseContext.Provider value={new Firebase()}>
            <App/>
        </FirebaseContext.Provider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
