import React, { Component } from 'react'
import './Footer.css'

class Footer extends Component {
    render() {
        return (
            <div className="footer" id="footer">
                <div className="footer-content">
                    <div className="footer-text">
                        Følg os på de social medier
                    </div>
                    {/* <!-- <div className="footer-img">
                        <img className="footer-icon" src="../images/icons/mustache-curled-tip-variant.svg" alt="">
                    </div> --> */}
                    <div className="footer-icons flex">
                        <a href="https://www.facebook.com/royalcuthelsingor/" target="_blank" rel="noopener noreferrer"> {/* rel="noopener noreferrer" is used for security. See (https://mathiasbynens.github.io/rel-noopener/)*/}
                            <div className="circle-border">
                                <img className="social-media-icon" src={require("../../../../images/icons/facebook-letter-logo-white.svg")} alt=""/>
                            </div>
                        </a>
                        
                        <a href="https://www.instagram.com/royalcuthelsingor/" target="_blank" rel="noopener noreferrer">
                            <div className="circle-border">
                                <img className="social-media-icon" src={require("../../../../images/icons/instagram-logo-white.svg")} alt=""/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
