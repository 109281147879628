import React, { Component } from 'react'
import PropTypes from 'prop-types';
import * as ROUTES from '../../constants/routes';
import SignOutButton from '../SignOut/SignOutButton';
import SignOutButtonSmall from '../SignOut/SignOutButtonSmall.js';

class NavigationAuth extends Component {

    render() {
        return (
            <div className="nav" id="navbar" >
                <div className="normal-nav hide-small" id="normal-nav">{/*<!-- <div className="bar"> -->*/}
                    {/* <!-- Normal Nav menu --> */}
                    {/* <Link to={ROUTES.LANDING}>Landing</Link> */}
                    <a href="/#home" className="nav-btn hide-small">Hjem</a>
                    <a href="/#aboutUs" className="nav-btn hide-small">Om Os</a>
                    <a href="/#prices" className="nav-btn hide-small">Priser</a>
                    <a href="/#team" className="nav-btn hide-small">Team</a>
                    <a href="/#contact" className="nav-btn hide-small">Kontakt</a>
                    <span className="right">
                        <a href={ROUTES.ADMIN_AREA_HOME} className="nav-btn hide-small">Admin side</a>
                        <SignOutButton/>
                    </span>
                </div>
                

                {/* <!-- Menu button (small-screen) --> */}
                <a className="nav-btn hide-medium hide-large right" /*href="javascript:void(0);"*/ onClick={this.props.toggleFunction} title="Toggle Navigation Menu">
                        <i className="fa fa-bars"></i>
                </a>
                {/* <!-- Small Nav menu (small screens) --> */}
                <div className="mini-nav hide-medium hide-large links-hide mini-nav-background" id="mini-nav">
                    <div>
                        <a href="/#home" className="mini-nav-btn" onClick={this.props.toggleFunction}>Hjem</a>
                    </div>
                    <div>
                        <a href="/#aboutUs" className="mini-nav-btn" onClick={this.props.toggleFunction}>Om Os</a>
                    </div>
                    <div>
                        <a href="/#prices" className="mini-nav-btn" onClick={this.props.toggleFunction}>Priser</a>
                    </div>
                    <div>
                        <a href="/#team" className="mini-nav-btn" onClick={this.props.toggleFunction}>Team</a>
                    </div>
                    <div>
                        <a href="/#contact" className="mini-nav-btn" onClick={this.props.toggleFunction}>Kontakt</a>
                    </div>
                    <div>
                        <a href={ROUTES.ADMIN_AREA_HOME} className="mini-nav-btn" onClick={this.props.toggleFunction}>Admin Area</a>
                    </div>
                    <div>
                        <SignOutButtonSmall />
                    </div>
                </div>
            </div>
        )
    }
}

export default NavigationAuth;


//PropTypes
NavigationAuth.propTypes = { //this component recieves the following props
    toggleFunction: PropTypes.func //'toggleFunction' is a function
}