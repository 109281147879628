/* the session reducer which manages the authUser object. The authenticated user represents the session in the application.
The reducer deals only with one incoming action which either sets the authUser to the actual authenticated user or null */


const INITIAL_STATE = {
    authUser: null
}


/* Reducer:
Specifies how the applications state changes

In term of code a reducer is a function that accepts 'state' and 'action' as arguments, and return the 'next state' of the application.
(previousState, action) => newState
*/
function sessionReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'AUTH_USER_SET': return { //if action type is 'BUY_CAKE', the do this
            ...state, //our state object may contain more than 1 property, that's why it is always safer to make a copy of the state object (using the spread operator '...'). This will make sure, that if there were other properties, then they'll stay unchanged.
            authUser: action.authUser //updates the 'authUser' state
        }

        default:
            return state; //if we don't have the type defined in the reducer, we just return the state
    }
}

export default sessionReducer;

