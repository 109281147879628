import React, { Component } from 'react'
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../../../Session';
import '../Admin.css'
import AdminAreaNav from '../../AdminAreaNav';
//import AboutUsForm from '../AdminAboutUs/AboutUsForm'
import AboutUsFormHook from '../AdminAboutUs/AboutUsFormHook'

class AdminAboutUs extends Component {
    render() {
        return (
            <div>
                <AdminAreaNav />
                <h3 className="admin-title">Om os (Admin)</h3>
                <AboutUsFormHook />
            </div>
        )
    }
}

const condition = authUser => !!authUser;

//export default AdminAboutUs
export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(AdminAboutUs);