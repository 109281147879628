/* we'll connect the Firebase with the React world.
we'll use React's Context API to provide a Firebase instance once at the top-level of your component hierarchy. */
import React from 'react';

/* We initialize the 'createContext' function.
The createContext() function essentially creates two components:
    - FirebaseContext.Provider
    - FirebaseContext.Consumer

The FirebaseContext.Provider component is used to provide a Firebase instance once at the top-level of your
React component tree (we use it in the Root 'index.js' file)

The FirebaseContext.Consumer component is used to retrieve the Firebase instance if it is needed in
the React component. */
const FirebaseContext = React.createContext(null);

/* Rather than using a render prop component, which is automatically given with React's Context Consumer component, 
it may be simpler to use a higher-order component. Let's implement this higher-order component in here */
const withFirebase = (Component) => //'withFirebase' is a HoC (Higher order Counter) component, which accepts a Component and returns another Component.
    props => (
        /* We make the Firebase instance available here by utilizing our Firebase Context and by passing the Firebase instance */
        <FirebaseContext.Consumer>
            {(firebase) =>
            <Component {...props} firebase={firebase} />}
        </FirebaseContext.Consumer>
    );


export default FirebaseContext;
export { withFirebase }; //export the function so we can use it