import React from 'react'
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../../../Session';
import AdminAreaNav from '../../AdminAreaNav';
//import TeamForm from '../AdminTeam/TeamForm';
import TeamFormHook from '../AdminTeam/TeamFormHook';

const AdminTeamHook = () => {
    return (
        <div>
            <AdminAreaNav />
            <h3 className="admin-title">Team (Admin)</h3>
            <TeamFormHook />
        </div>
    )
}

const condition = authUser => !!authUser;

//export default AdminTeam
export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(AdminTeamHook);