import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';

const AboutUsFormHook = (props) => {
    // Declare state variables, which we'll call "inputValues"
    const [inputValues, setInputValues] = useState({ //'inputValues' used to get the data  &  'setInputValues' used to set the data
        loading: false,
        title: '',
        preTitle: '',
        aboutPart1: '',
        aboutPart2: '',
        timeTitle: '',
        day1: '',
        time1: '',
        day2: '',
        time2: '',
        day3: '',
        time3: '',
        address: '',
        phone: '',
    })

    // Declare state variables for errors
    const [errors, setErrors] = useState({
        title: '',
        preTitle: '',
        aboutPart1: '',
        aboutPart2: '',
        timeTitle: '',
        day1: '',
        time1: '',
        day2: '',
        time2: '',
        day3: '',
        time3: '',
        address: '',
        phone: '',
    })

    useEffect(() => { // Similar to componentDidMount and componentDidUpdate:
        onListenForData();

        return () => { // Specify how to clean up after this effect: //remove the listener to avoid memory leaks

        };

    }, []);

    //(using Cloud Firestore)
    const onListenForData = () => {
        setInputValues({
            ...inputValues, //we leave the states as they are
            loading: true, //and update only this
        })

        const unsubscribe =
            props.firebase
                .aboutUs('data') //'data' is my id in the db
                .onSnapshot(snapshot => {

                    props.setReduxTitle(snapshot.data().title);
                    props.setReduxPreTitle(snapshot.data().preTitle);

                    props.setReduxAboutPart1(snapshot.data().aboutPart1);
                    props.setReduxAboutPart2(snapshot.data().aboutPart2);
                    props.setReduxTimeTitle(snapshot.data().timeTitle);
                    props.setReduxDay1(snapshot.data().day1);
                    props.setReduxTime1(snapshot.data().time1);
                    props.setReduxDay2(snapshot.data().day2);
                    props.setReduxTime2(snapshot.data().time2);
                    props.setReduxDay3(snapshot.data().day3);
                    props.setReduxTime3(snapshot.data().time3);
                    props.setReduxAddress(snapshot.data().address);
                    props.setReduxPhone(snapshot.data().phone);

                    setInputValues({
                        //...inputValues,
                        loading: false,
                        title: snapshot.data().title,
                        preTitle: snapshot.data().preTitle,
                        aboutPart1: snapshot.data().aboutPart1,
                        aboutPart2: snapshot.data().aboutPart2,
                        timeTitle: snapshot.data().timeTitle,
                        day1: snapshot.data().day1,
                        time1: snapshot.data().time1,
                        day2: snapshot.data().day2,
                        time2: snapshot.data().time2,
                        day3: snapshot.data().day3,
                        time3: snapshot.data().time3,
                        address: snapshot.data().address,
                        phone: snapshot.data().phone,
                    })

                });
    }


    const onChange = event => {
        setInputValues({
            ...inputValues, //we leave the states as they are
            [event.target.name]: event.target.value //and update only this
            //ex. username      :   legolas
        })

        setErrors({
            ...errors,
            [event.target.name]: ''
        })
        props.setReduxIsSaveSuccess(false); //to remove the 'save success' message if displayed
    };

    const onSave = (event) => {
        let isValid = validate(); //handle errors

        if (isValid) { //if 'isValid' is 'true'
            try {
                props.firebase.aboutUs('data').set({
                    title: inputValues.title, //update the 'title' in db
                    preTitle: inputValues.preTitle,
                    aboutPart1: inputValues.aboutPart1,
                    aboutPart2: inputValues.aboutPart2,
                    timeTitle: inputValues.timeTitle,
                    day1: inputValues.day1,
                    time1: inputValues.time1,
                    day2: inputValues.day2,
                    time2: inputValues.time2,
                    day3: inputValues.day3,
                    time3: inputValues.time3,
                    address: inputValues.address,
                    phone: inputValues.phone,
                })

                props.setReduxIsSaveSuccess(true); //to display 'save success' message
            } catch (error) {
                console.log(error)
            }
        }
        event.preventDefault(); //(prevent a browser reload/refresh) the preventDefault() method on the event prevents a reload of the browser which otherwise would be a natural behavior when using a submit in a form. 
    };


    const validate = () => {
        // console.log("validate")
        //let emailRegex = /\S+@\S+\.\S+/;

        let allErrors = {};

        if (inputValues.preTitle.length > 45) {
            allErrors.preTitle = "Pre-Titel kan maks indeholde 45 tegn";
        }

        if (!inputValues.title) {
            allErrors.title = "Titel er påkrævet";
        } else if (inputValues.title.length < 3) {
            allErrors.title = "Titel skal mindst have 3 tegn";
        } else if (inputValues.title.length > 44) {
            allErrors.title = "Titel kan maks indeholde 44 tegn (helst 22 tegn)";
        }

        if (inputValues.aboutPart1.length > 750) {
            allErrors.aboutPart1 = "Om Os part 1 - kan maks indeholde 750 tegn (helst under 669 tegn)";
        }

        if (inputValues.aboutPart2.length > 1010) {
            allErrors.aboutPart2 = "Om Os part 2 - kan maks indeholde 1010 tegn (helst under 669 tegn)";
        }

        if (!inputValues.timeTitle) {
            allErrors.timeTitle = "Tid Titel er påkrævet";
        } else if (inputValues.timeTitle.length < 3) {
            allErrors.timeTitle = "Tid Titel skal mindst have 3 tegn";
        } else if (inputValues.timeTitle.length > 16) {
            allErrors.timeTitle = "Tid Titel kan maks indeholde 16 tegn";
        }

        if (!inputValues.day1) {
            allErrors.day1 = "Dag1 er påkrævet";
        } else if (inputValues.day1.length < 3) {
            allErrors.day1 = "Dag1 skal mindst have 3 tegn";
        } else if (inputValues.day1.length > 40) {
            allErrors.day1 = "Dag1 kan maks indeholde 40 tegn";
        }

        if (!inputValues.time1) {
            allErrors.time1 = "Tid1 er påkrævet";
        } else if (inputValues.time1.length < 3) {
            allErrors.time1 = "Tid1 skal mindst have 3 tegn";
        } else if (inputValues.time1.length > 40) {
            allErrors.time1 = "Tid1 kan maks indeholde 40 tegn";
        }

        if (!inputValues.day2) {
            allErrors.day2 = "Dag2 er påkrævet";
        } else if (inputValues.day2.length < 3) {
            allErrors.day2 = "Dag2 skal mindst have 3 tegn";
        } else if (inputValues.day2.length > 40) {
            allErrors.day2 = "Dag2 kan maks indeholde 40 tegn";
        }

        if (!inputValues.time2) {
            allErrors.time2 = "Tid2 er påkrævet";
        } else if (inputValues.time2.length < 3) {
            allErrors.time2 = "Tid2 skal mindst have 3 tegn";
        } else if (inputValues.time2.length > 40) {
            allErrors.time2 = "Tid2 kan maks indeholde 40 tegn";
        }

        if (!inputValues.day3) {
            allErrors.day3 = "Dag3 er påkrævet";
        } else if (inputValues.day3.length < 3) {
            allErrors.day3 = "Dag3 skal mindst have 3 tegn";
        } else if (inputValues.day3.length > 40) {
            allErrors.day3 = "Dag3 kan maks indeholde 40 tegn";
        }

        if (!inputValues.time3) {
            allErrors.time3 = "Tid3 er påkrævet";
        } else if (inputValues.time3.length < 3) {
            allErrors.time3 = "Tid3 skal mindst have 3 tegn";
        } else if (inputValues.time3.length > 40) {
            allErrors.time3 = "Tid3 kan maks indeholde 40 tegn";
        }

        if (!inputValues.address) {
            allErrors.address = "Adresse er påkrævet";
        } else if (inputValues.address.length < 3) {
            allErrors.address = "Adresse skal mindst have 3 tegn";
        } else if (inputValues.address.length > 40) {
            allErrors.address = "Adresse kan maks indeholde 40 tegn";
        }

        if (!inputValues.phone) {
            allErrors.phone = "Telefon er påkrævet";
        } else if (inputValues.phone.length < 3) {
            allErrors.phone = "Telefon skal mindst have 8 tegn";
        } else if (inputValues.phone.length > 40) {
            allErrors.phone = "Telefon kan maks indeholde 40 tegn";
        }

        setErrors({
            title: allErrors.title,
            preTitle: allErrors.preTitle,
            aboutPart1: allErrors.aboutPart1,
            aboutPart2: allErrors.aboutPart2,
            timeTitle: allErrors.timeTitle,
            day1: allErrors.day1,
            time1: allErrors.time1,
            day2: allErrors.day2,
            time2: allErrors.time2,
            day3: allErrors.day3,
            time3: allErrors.time3,
            address: allErrors.address,
            phone: allErrors.phone,
        });

        //console.log("allErrors length: " + Object.keys(allErrors).length);

        if (Object.keys(allErrors).length > 0) {
            //console.log("return false");
            return false;
        } else {
            //console.log("return true");
            return true;
        }
    }


    const { getReduxTitle, getReduxPreTitle, getReduxAboutPart1, getReduxAboutPart2, getReduxTimeTitle, getReduxDay1, getReduxTime1, getReduxDay2, getReduxTime2, getReduxDay3, getReduxTime3, getReduxAddress, getReduxPhone, getReduxIsSaveSuccess } = props;

    const isInvalid =
        inputValues.title === getReduxTitle && //if 'title' is equals 'getReduxTitle', then it 'isInvalid' is true
        inputValues.preTitle === getReduxPreTitle &&
        inputValues.aboutPart1 === getReduxAboutPart1 &&
        inputValues.aboutPart2 === getReduxAboutPart2 &&
        inputValues.timeTitle === getReduxTimeTitle &&
        inputValues.day1 === getReduxDay1 &&
        inputValues.time1 === getReduxTime1 &&
        inputValues.day2 === getReduxDay2 &&
        inputValues.time2 === getReduxTime2 &&
        inputValues.day3 === getReduxDay3 &&
        inputValues.time3 === getReduxTime3 &&
        inputValues.address === getReduxAddress &&
        inputValues.phone === getReduxPhone;


    return (
        <div className="marginBottomBig" /*center-this */>

            {inputValues.loading && <div className="center-this">Loading ...</div>}

            <form onSubmit={event => onSave(event)} noValidate> {/* We disable the default form validation (ex. in the email input field, it will no longer validate if theres actually an email or not). We do this because we want to validate the data ourselves. */}

                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Pre-Titel:</label>
                    </div>

                    <input className={`inputStyling ${errors.preTitle && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="preTitle"
                        type="text"
                        value={inputValues.preTitle}
                        onChange={onChange}
                    />
                </div>
                {errors.preTitle &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.preTitle}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Titel:</label>
                    </div>

                    <input className={`inputStyling ${errors.title && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="title"
                        type="text"
                        value={inputValues.title}
                        onChange={onChange}
                    />
                </div>
                {errors.title &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.title}</p>
                    </div>
                }


                <br></br>


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Om Os part 1:</label>
                    </div>
                    <textarea className={`inputStyling ${errors.aboutPart1 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="aboutPart1"
                        type="text"
                        value={inputValues.aboutPart1}
                        onChange={onChange}
                        rows="7"
                    //cols="20"
                    />
                </div>
                {errors.aboutPart1 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.aboutPart1}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Om Os part 2:</label>
                    </div>

                    <textarea className={`inputStyling ${errors.aboutPart2 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="aboutPart2"
                        type="text"
                        value={inputValues.aboutPart2}
                        onChange={onChange}
                        rows="7"
                    //cols="20"
                    />
                </div>
                {errors.aboutPart2 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.aboutPart2}</p>
                    </div>
                }


                <br></br>


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Tid Titel:</label>
                    </div>

                    <input className={`inputStyling ${errors.timeTitle && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="timeTitle"
                        type="text"
                        value={inputValues.timeTitle}
                        onChange={onChange}
                    />
                </div>
                {errors.timeTitle &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.timeTitle}</p>
                    </div>
                }


                <br></br>


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Dag 1:</label>
                    </div>

                    <input className={`inputStyling ${errors.day1 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="day1"
                        type="text"
                        value={inputValues.day1}
                        onChange={onChange}
                    />
                </div>
                {errors.day1 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.day1}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Tid 1:</label>
                    </div>

                    <input className={`inputStyling ${errors.time1 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="time1"
                        type="text"
                        value={inputValues.time1}
                        onChange={onChange}
                    />
                </div>
                {errors.time1 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.time1}</p>
                    </div>
                }


                <br></br>


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Dag 2:</label>
                    </div>

                    <input className={`inputStyling ${errors.day2 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="day2"
                        type="text"
                        value={inputValues.day2}
                        onChange={onChange}
                    />
                </div>
                {errors.day2 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.day2}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Tid 2:</label>
                    </div>

                    <input className={`inputStyling ${errors.time2 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="time2"
                        type="text"
                        value={inputValues.time2}
                        onChange={onChange}
                    />
                </div>
                {errors.time2 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.time2}</p>
                    </div>
                }


                <br></br>


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Dag 3:</label>
                    </div>

                    <input className={`inputStyling ${errors.day3 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="day3"
                        type="text"
                        value={inputValues.day3}
                        onChange={onChange}
                    />
                </div>
                {errors.day3 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.day3}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Tid 3:</label>
                    </div>

                    <input className={`inputStyling ${errors.time3 && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="time3"
                        type="text"
                        value={inputValues.time3}
                        onChange={onChange}
                    />
                </div>
                {errors.time3 &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.time3}</p>
                    </div>
                }


                <br></br>


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Adresse:</label>
                    </div>

                    <input className={`inputStyling ${errors.address && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="address"
                        type="text"
                        value={inputValues.address}
                        onChange={onChange}
                    />
                </div>
                {errors.address &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.address}</p>
                    </div>
                }


                <div className="flex flexWrap center-flex marginBottom">   {/* wrap */}
                    <div className="labelStyling">
                        <label className="flex flexEnd">Telefon:</label>
                    </div>

                    <input className={`inputStyling ${errors.phone && "inputError"}`} //set 'inputStyling' as className and if we have an error, then set 'inputError' as className aswell
                        name="phone"
                        type="text"
                        value={inputValues.phone}
                        onChange={onChange}
                    />
                </div>
                {errors.phone &&
                    <div className="flex flexWrap center-flex marginBottom">
                        <div className="labelStyling"> </div>
                        <p className="errorMessage inputStyling">{errors.phone}</p>
                    </div>
                }


                <div className="center-this">
                    <button className="save-button-padding center-this" type="submit" disabled={isInvalid} >Gem</button> {/* Button is disabled if 'isInvalid' is true */}

                    <br></br>

                    {getReduxIsSaveSuccess &&
                        <label className="inputStyling sucessMessage">Gemt successfuldt</label>
                    }
                </div>



            </form>

        </div>
    )
}



//get
const mapStateToProps = (state) => ({
    getReduxTitle: state.aboutUsState.title, //get the state from aboutUsState -> title
    getReduxPreTitle: state.aboutUsState.preTitle,
    getReduxAboutPart1: state.aboutUsState.aboutPart1,
    getReduxAboutPart2: state.aboutUsState.aboutPart2,
    getReduxTimeTitle: state.aboutUsState.timeTitle,
    getReduxDay1: state.aboutUsState.day1,
    getReduxTime1: state.aboutUsState.time1,
    getReduxDay2: state.aboutUsState.day2,
    getReduxTime2: state.aboutUsState.time2,
    getReduxDay3: state.aboutUsState.day3,
    getReduxTime3: state.aboutUsState.time3,
    getReduxAddress: state.aboutUsState.address,
    getReduxPhone: state.aboutUsState.phone,
    getReduxIsSaveSuccess: state.aboutUsState.isSaveSuccess,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxTitle: (x) =>
        dispatch({ type: 'TITLE_SET', x }), //sends/dispatches the 'title' with type 'TITLE_SET' so the redux reduxer knows what to do with it

    setReduxPreTitle: (x) =>
        dispatch({ type: 'PRETITLE_SET', x }),

    setReduxAboutPart1: (x) =>
        dispatch({ type: 'ABOUTPART1_SET', x }),

    setReduxAboutPart2: (x) =>
        dispatch({ type: 'ABOUTPART2_SET', x }),

    setReduxTimeTitle: (x) =>
        dispatch({ type: 'TIMETITLE_SET', x }),

    setReduxDay1: (x) =>
        dispatch({ type: 'DAY1_SET', x }),

    setReduxTime1: (x) =>
        dispatch({ type: 'TIME1_SET', x }),

    setReduxDay2: (x) =>
        dispatch({ type: 'DAY2_SET', x }),

    setReduxTime2: (x) =>
        dispatch({ type: 'TIME2_SET', x }),

    setReduxDay3: (x) =>
        dispatch({ type: 'DAY3_SET', x }),

    setReduxTime3: (x) =>
        dispatch({ type: 'TIME3_SET', x }),

    setReduxAddress: (x) =>
        dispatch({ type: 'ADDRESS_SET', x }),

    setReduxPhone: (x) =>
        dispatch({ type: 'PHONE_SET', x }),

    setReduxIsSaveSuccess: (x) =>
        dispatch({ type: 'ISSAVESUCCESS_SET', x }),
});

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(AboutUsFormHook);