import React, { Component } from 'react'
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';
import './AboutSection.css'

class AboutSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preTitle: '',
            title: '',
            aboutPart1: '',
            aboutPart2: '',
            timeTitle: '',
            day1: '',
            time1: '',
            day2: '',
            time2: '',
            day3: '',
            time3: '',
            address: '',
            phone: '',
        };
    }
    
    componentDidMount() {
        this.onListenForAboutUs();
    }

    onListenForAboutUs(){
        this.unsubscribe = this.props.firebase
            .aboutUs('data') //'data' is my id in the db
            .onSnapshot(snapshot => {
                //this.props.setReduxAbout(snapshot.data().about);
                this.setState({
                    preTitle: snapshot.data().preTitle,
                    title: snapshot.data().title,
                    aboutPart1: snapshot.data().aboutPart1,
                    aboutPart2: snapshot.data().aboutPart2,
                    timeTitle: snapshot.data().timeTitle,
                    day1: snapshot.data().day1,
                    time1: snapshot.data().time1,
                    day2: snapshot.data().day2,
                    time2: snapshot.data().time2,
                    day3: snapshot.data().day3,
                    time3: snapshot.data().time3,
                    address: snapshot.data().address,
                    phone: snapshot.data().phone,
                });
            });
    }

    componentWillUnmount() {
        this.unsubscribe(); //(using Cloud Firestore)
    }


    render() {
        return (
            // <!-- (About Section) -->
            <div className="aboutUs" id="aboutUs">
                <div className="aboutUs-content flex-wrap">


                    <div className="about-left-side hide-small">
                        <div className="rectangle-area">
                            <img className="rectangle-image " src={require("../../../../images/gold3.jpg")} alt="rectangle" /> 
                            <div className="rectangle-content">
                                
                                <div className="icon-area">
                                    <img className="timing-icon-big" src={require("../../../../images/icons/scissors.svg")}  alt="icon"/>
                                </div>
                                <div className="timing">
                                    {/* Åbningstider */}
                                    {this.state.timeTitle}
                                    <span className="lineTiming"></span>
                                </div>

                                <div className="timing-title" >
                                    {/* Mandag - Fredag */}
                                    {this.state.day1}
                                    <div className="timing-content">
                                        {/* 09.00 - 17.30 */}
                                        {this.state.time1}
                                    </div>
                                </div>
                                <div className="timing-title" >
                                    {/* Lørdag */}
                                    {this.state.day2}
                                    <div className="timing-content">
                                        {/* 09.00 - 15.00 */}
                                        {this.state.time2}
                                    </div>
                                </div>
                                <div className="timing-title" >
                                    {/* Søndag */}
                                    {this.state.day3}
                                    <div className="timing-content">
                                        {/* Lukket */}
                                        {this.state.time3}
                                    </div>
                                </div>
                                <div className="timing-title">
                                    <img className="timing-icon" src={require("../../../../images/icons/map-marker.svg")}  alt="icon"/>
                                    <div className="timing-content">
                                        {/* Rosenkildevej 4A
                                        Helsingør, Denmark */}
                                        {this.state.address}
                                    </div>
                                </div>
                                <div className="timing-title">
                                    <img className="timing-icon" src={require("../../../../images/icons/phone-receiver.svg")}  alt="icon"/>
                                    <div className="timing-content">
                                        {/* 50 23 60 23 */}
                                        {this.state.phone}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>


                    <div className="about-left-side hide-medium hide-large">
                        <div className="rectangle-area-mobile">
                            <img className="rectangle-image " src={require("../../../../images/gold3.jpg")} alt="rectangle" /> 
                            <div className="rectangle-content">
                                
                                <div className="icon-area">
                                    <img className="timing-icon-big" src={require("../../../../images/icons/scissors.svg")}  alt="icon"/>
                                </div>
                                <div className="timing">
                                    {/* Åbningstider */}
                                    {this.state.timeTitle}
                                    <span className="lineTiming"></span>
                                </div>

                                <div className="timing-title" >
                                    {/* Mandag - Fredag */}
                                    {this.state.day1}
                                    <div className="timing-content">
                                        {/* 09.00 - 17.30 */}
                                        {this.state.time1}
                                    </div>
                                </div>
                                <div className="timing-title" >
                                    {/* Lørdag */}
                                    {this.state.day2}
                                    <div className="timing-content">
                                        {/* 09.00 - 15.00 */}
                                        {this.state.time2}
                                    </div>
                                </div>
                                <div className="timing-title" >
                                    {/* Søndag */}
                                    {this.state.day3}
                                    <div className="timing-content">
                                        {/* Lukket */}
                                        {this.state.time3}
                                    </div>
                                </div>
                                <div className="timing-title">
                                    <img className="timing-icon" src={require("../../../../images/icons/map-marker.svg")}  alt="icon"/>
                                    <div className="timing-content">
                                        {/* Rosenkildevej 4A
                                        Helsingør, Denmark */}
                                        {this.state.address}
                                    </div>
                                </div>
                                <div className="timing-title">
                                    <img className="timing-icon" src={require("../../../../images/icons/phone-receiver.svg")}  alt="icon"/>
                                    <div className="timing-content">
                                        {/* 50 23 60 23 */}
                                        {this.state.phone}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    
                    <div className="about-right-side">
                        
                        <div className="text-left">
                                <h6 className="about-title-1">
                                    {/* The Unique Story */}
                                    {this.state.preTitle}
                                </h6>
                                <h2 className="about-title-2">
                                    {/* 52 Years Of Experience */}
                                    {this.state.title}
                                </h2>
                                <span className="line"></span>

                                <p className="p1 renderNewLine hide-small">
                                    {/* Hos Royal Cut tilbyder vi byens bedste klipning & gammeldags barbering til en god og rimelig pris. */}
                                    {this.state.aboutPart1}
                                </p>
                                <p className="p2 renderNewLine hide-small">
                                    {/* Vi lægger vægt på at De som kunde får en unik oplevelse og den bedst mulige service. Vi bestræber os på at gøre en klipning særlig for dem som kunde. For os er det vigtigt, at kunden føler sig i centrum med de bedste omgivelser og i de allerbedste hænder. Vil De høre mere om vores ydelser, så er De altid velkommen til at kontakte os. */}
                                    {this.state.aboutPart2}
                                </p>

                                <p className="p1-mobile renderNewLine hide-medium hide-large">
                                    {/* Hos Royal Cut tilbyder vi byens bedste klipning & gammeldags barbering til en god og rimelig pris. */}
                                    {this.state.aboutPart1}
                                </p>
                                <p className="p2-mobile renderNewLine hide-medium hide-large">
                                    {/* Vi lægger vægt på at De som kunde får en unik oplevelse og den bedst mulige service. Vi bestræber os på at gøre en klipning særlig for dem som kunde. For os er det vigtigt, at kunden føler sig i centrum med de bedste omgivelser og i de allerbedste hænder. Vil De høre mere om vores ydelser, så er De altid velkommen til at kontakte os. */}
                                    {this.state.aboutPart2}
                                </p>
                        </div>
                        <div className="hide-small">
                                <img className="brush-image" src={require("../../../../images/shavingbrush.png")}  alt="brush"/>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(
    withFirebase
)(AboutSection)
