/* The message reducer deals with the list of messages from the Firebase realtime database. Again, it sets the whole
 object of messages as dictionary, but also a limit for the pagination feature we implemented  */

const INITIAL_STATE = {
    messages: null,
    limit: 5
};



function messageReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'MESSAGES_SET': return { 
            ...state,
            messages: action.messages
        }
        case 'MESSAGES_LIMIT_SET': return {
            ...state,
            limit: action.limit,
        }

        default:
            return state;
    }
}

export default messageReducer;
