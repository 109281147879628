

 const INITIAL_STATE = {
    employeesArray: null,
    showAddEmployeeModal: "false",
    showDeleteEmployeeModal: "false",
    showEditEmployeeModal: "false",
    targetedEmployee: null,
    isSaveSuccess: false,
};



function teamReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'EMPLOYEES_SET': return { 
            ...state,
            employeesArray: action.x
        }

        case 'SHOW_ADD_EMPLOYEE_MODAL': return { 
            ...state,
            showAddEmployeeModal: action.x
        }

        case 'SHOW_DELETE_EMPLOYEE_MODAL': return { 
            ...state,
            showDeleteEmployeeModal: action.x,
        }

        case 'SHOW_EDIT_EMPLOYEE_MODAL': return { 
            ...state,
            showEditEmployeeModal: action.x,
        }

        case 'SET_TARGETED_EMPLOYEE': return { 
            ...state,
            targetedEmployee: action.x
        }

        case 'ISSAVESUCCESS_SET': return {
            ...state,
            isSaveSuccess: action.x
        }
        
        default:
            return state;
    }
}

export default teamReducer;
