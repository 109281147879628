import { combineReducers } from 'redux';

import sessionReducer from './session';
import userReducer from './user';
import messageReducer from './message';
import aboutUsReducer from './aboutUs';
import servicesReducer from './services';
import contactReducer from './contact';
import teamReducer from './team';

/*  Combine all reducers into a root reducer in the src/reducers/index.js file to make it accessible for the store creation */

const rootReducer = combineReducers({
    sessionState: sessionReducer,
    userState: userReducer,
    messageState: messageReducer,
    
    aboutUsState: aboutUsReducer,
    servicesState: servicesReducer,
    contactState: contactReducer,
    teamState: teamReducer,
});

export default rootReducer;