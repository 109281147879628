import React, { Component } from 'react'

//import './AdminArea.css';

/* It uses the Link component of React Router to enable navigation to different routes.
These routes were defined previously in your constants file.
Let's import all of them and give every Link component a specific route. */
import * as ROUTES from '../../constants/routes';

import SignOutButton from '../SignOut/SignOutButton';
import SignOutButtonSmall from '../SignOut/SignOutButtonSmall.js';

//import AdminArea from './AdminArea.js'

//import { AuthUserContext } from '../Session';
import { connect } from 'react-redux';





class AdminAreaNav extends Component {

    componentDidMount = () => {
        document.addEventListener('scroll', this.navScrollMode); //we add a 'scroll' event listener, which will fire/run our 'navScrollMode' javaScript after scrolling is trigered.
    }

    
    navScrollMode = () => {
        //console.log("handleScroll");
    
            var navbar = document.getElementById("navbar");
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        
                var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width; //get the screen width in px
        
                if (width>600){ //if width is larger than 600px (we only wants to add the 'nav-on-scroll' on large devices/screens)
                    navbar.classList.add('nav-on-scroll'); //add the 'nav-on-scroll' css to the navbar when scrolled
                }
            } else {
                navbar.classList.remove('nav-on-scroll'); //remove 'nav-on-scroll'
            }
        
        //console.log(width);
    }


    
    // Used to toggle the menu on small screens when clicking on the menu button (or selecting links)
    toggleFunction = () => {
        var miniNav = document.getElementById("mini-nav");
        if (miniNav.className.indexOf("links-show") === -1) { //if miniNav doesn't contain 'links-show' class
            miniNav.classList.add('links-show'); //add 'links-show' to miniNav
        } else {
            miniNav.classList.remove('links-show'); //removes 'links-show' from miniNav
        }
    }





    //Navigation2 = ({getReduxAuthUser}) =>
        /* if authUser is true, then use 'NavigationAuth' else 'NavigationNonAuth' */
        //getReduxAuthUser ? <NavigationAuth authUser={getReduxAuthUser}/> : <NavigationNonAuth/>


    


    

//export default Navigation;


    render() {
        return (
                <div className="nav" id="navbar" >
                    <div className="normal-nav hide-small" id="normal-nav">{/*<!-- <div className="bar"> -->*/}
                        {/* <!-- Normal Nav menu --> */}
                        {/* <Link to={ROUTES.LANDING}>Landing</Link> */}
                        <a href={ROUTES.ADMIN_AREA_HOME} className="nav-btn hide-small">Hjem_</a>
                        <a href={ROUTES.ADMIN_AREA_ABOUT_US} className="nav-btn hide-small">Om Os_</a>
                        <a href={ROUTES.ADMIN_AREA_PRICES} className="nav-btn hide-small">Priser_</a>
                        <a href={ROUTES.ADMIN_AREA_TEAM} className="nav-btn hide-small">Team_</a>
                        <a href={ROUTES.ADMIN_AREA_CONTACT} className="nav-btn hide-small">Kontakt_</a>
                        <span className="right">
                            <a href={ROUTES.HOME} className="nav-btn hide-small">Offentlig side</a>
                            <SignOutButton/>
                        </span>
                    </div>
                    
    
                    {/* <!-- Menu button (small-screen) --> */}
                    <a className="nav-btn hide-medium hide-large right" /*href="javascript:void(0);"*/ onClick={this.toggleFunction} title="Toggle Navigation Menu">
                            <i className="fa fa-bars"></i>
                    </a>
                    {/* <!-- Small Nav menu (small screens) --> */}
                    <div className="mini-nav hide-medium hide-large links-hide mini-nav-background" id="mini-nav">
                        <div>
                            <a href={ROUTES.ADMIN_AREA_HOME} className="mini-nav-btn" onClick={this.toggleFunction}>Hjem_</a>
                        </div>
                        <div>
                            <a href={ROUTES.ADMIN_AREA_ABOUT_US} className="mini-nav-btn" onClick={this.toggleFunction}>Om Os_</a>
                        </div>
                        <div>
                            <a href={ROUTES.ADMIN_AREA_PRICES} className="mini-nav-btn" onClick={this.toggleFunction}>Priser_</a>
                        </div>
                        <div>
                            <a href={ROUTES.ADMIN_AREA_TEAM} className="mini-nav-btn" onClick={this.toggleFunction}>Team_</a>
                        </div>
                        <div>
                            <a href={ROUTES.ADMIN_AREA_CONTACT} className="mini-nav-btn" onClick={this.toggleFunction}>Kontakt_</a>
                        </div>
                        <div>
                            <a href={ROUTES.HOME} className="mini-nav-btn" onClick={this.toggleFunction}>Offentlig Side_</a>
                        </div>
                        <div>
                            <SignOutButtonSmall />
                        </div>
                    </div>
                </div>
            // <div>
                //{/* {this.props.getReduxAuthUser ? ( /* if authUser is true, then use 'NavigationAuth' else 'NavigationNonAuth' */ }
                    //{/* <NavigationAdminArea toggleFunction={this.toggleFunction} authUser={this.props.getReduxAuthUser} />
                /*) : (
                    <NavigationNonAuth toggleFunction={this.toggleFunction} />
                )} *///}
            //     <AdminArea toggleFunction={this.toggleFunction} authUser={this.props.getReduxAuthUser} />
            // </div>
        )
    }
}


// const NavigationAuth = ({ authUser }) => {
//     return (
//         /*<div>
//             <ul>
//                 <li>
//                     <Link to={ROUTES.LANDING}>Landing</Link>
//                 </li>
//                 <li>
//                     <Link to={ROUTES.HOME}>Home</Link>
//                 </li>
//                 <li>
//                     <Link to={ROUTES.ACCOUNT}>Account</Link>
//                 </li>
//         */        
//                 {/* if authUser roles doesn't include a NULL admin role  (simplified: if roles is admin) */}
//         /*        {!!authUser.roles[ROLES.ADMIN] && ( //!!authUser      same as    authUser != null;
//                 <li>
//                     <Link to={ROUTES.ADMIN}>Admin</Link>
//                 </li>
//                 )}

//                 <li>
//                     <SignOutButton/>
//                 </li>
//             </ul>
//         </div>*/
//     )
// };







/* GET
mapStateToProps is used for selecting the part of the data from the store that the connected component needs.

- It is called every time the store state changes.
- It receives the entire store state, and should return an object of data this component needs. */
const mapStateToProps = state => ({
    getReduxAuthUser: state.sessionState.authUser //get the state from sessionStatate -> authuser
});

export default connect(mapStateToProps)(AdminAreaNav);





