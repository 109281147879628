import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
// import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import './SignIn.css'

const SignInPage = () => {
    return (
        <div className="login-content">
            <h1>SignIn</h1>
            <SignInForm/>
            <PasswordForgetLink/>
            {/* <SignUpLink/> */} {/* SignUpLink is defined in the SignUp module, but we use it on the sign in page. It lets users sign up if they don't have an account. */}
        </div>
    )
};

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null
};


class SignInFormBase extends Component {
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
      const { email, password } = this.state;

      this.props.firebase
        .doSignInWithEmailAndPassword(email, password) //call the login function defined in the Firebase class
        .then(() => {
          this.setState({ ...INITIAL_STATE }); //set the local state of the component to its initial state to empty the input fields.
          this.props.history.push(ROUTES.HOME); //Redirects the users to user's home page
        })
        .catch(error => {
          this.setState({ error }); //If the request is rejected, you will run into the catch block and set the error object in the local state. An error message should show up in the form due to the conditional rendering in your component's render method.
        });

      event.preventDefault();
    };

    onChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    render() {
      const { email, password, error } = this.state;

      const isInvalid = password === '' || email === '';

      return (
        <form onSubmit={this.onSubmit}>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="email"
            placeholder="Email Address"
          />

          <br></br>

          <input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />

          <br></br>

          <button disabled={isInvalid} type="submit">
            Sign In
          </button>

          {error && <p>{error.message}</p>}
          
        </form>
      );
    }
  }

const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInPage;
export { SignInForm };