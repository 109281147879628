
const INITIAL_STATE = {
    title: '',
    preTitle: '',
    aboutPart1: '',
    aboutPart2: '',
    timeTitle: '',
    day1: '',
    time1: '',
    day2: '',
    time2: '',
    day3: '',
    time3: '',
    address: '',
    phone: '',
    isSaveSuccess: false,
};



function aboutUsReducer(state = INITIAL_STATE, action) {
    // console.log("Current data (3): ", action.title);
    // console.log("Current data (4): ", INITIAL_STATE.title);

    switch(action.type) {
        case 'TITLE_SET': return { 
            ...state,
            title: action.x
        }
        
        case 'PRETITLE_SET': return {
            ...state,
            preTitle: action.x
        }

        case 'ABOUTPART1_SET': return {
            ...state,
            aboutPart1: action.x
        }

        case 'ABOUTPART2_SET': return {
            ...state,
            aboutPart2: action.x
        }

        case 'TIMETITLE_SET': return {
            ...state,
            timeTitle: action.x
        }

        case 'DAY1_SET': return {
            ...state,
            day1: action.x
        }

        case 'TIME1_SET': return {
            ...state,
            time1: action.x
        }

        case 'DAY2_SET': return {
            ...state,
            day2: action.x
        }

        case 'TIME2_SET': return {
            ...state,
            time2: action.x
        }

        case 'DAY3_SET': return {
            ...state,
            day3: action.x
        }

        case 'TIME3_SET': return {
            ...state,
            time3: action.x
        }

        case 'ADDRESS_SET': return {
            ...state,
            address: action.x
        }

        case 'PHONE_SET': return {
            ...state,
            phone: action.x
        }

        case 'ISSAVESUCCESS_SET': return {
            ...state,
            isSaveSuccess: action.x
        }

        default:
            return state; //if we don't have the type defined in the reducer, we just return the state
    }
}

export default aboutUsReducer;
