import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';

const DeleteServiceModalHook = (props) => {

    const onCloseDeleteServiceModal = () => {
        props.setReduxTargetedService(null);
        props.setReduxShowDeleteServiceModal(false);
    }

    const onDelete= () => {
        let id = props.getReduxTargetedService.id;
        //props.firebase.service(id).delete();

        updateOrderNumbers();

        props.setReduxTargetedService(null);
        props.setReduxShowDeleteServiceModal(false);
        
        props.firebase.service(id).delete();
        
    }

    const updateOrderNumbers = () => {
        let totalLength = props.getReduxServicesArray.length;
        let targetedOrder = props.getReduxTargetedService.order;

        /*for(let i=0; i<totalLength; i++){
            console.log("name: " + props.getReduxServicesArray[i].name);
        }*/

        for(let i=targetedOrder; i<totalLength; i++ ) {
            let orderOfRowBelow = i+1; //the 'order' number of the row below is '+1' more (therefore we plus/add)
            let rowBelow = props.getReduxServicesArray[orderOfRowBelow-1]; //then we get the complete data of the row above. We don't have to say '-1' because the 'orderOfRowBelow' is already -1 lower (we need it it -1 lower as arrays starts from '0').
        
            //decrease the 'order' number of the below row ('service')
            props.firebase.service(rowBelow.id).set({  //for the service in the below row (called 'rowBelow') we:
                ...rowBelow, //we keep all data
                order: orderOfRowBelow-1, //decrease the 'order' number (so it can go op (smallest first))
            });
        }
    }

    return (
        <div>
            <div className="greyOutBackground" onClick={() => onCloseDeleteServiceModal()}></div>
            <div className="delete-modal-container">

                <h3 className="font-this center-this makeMargin">Slet: {props.getReduxTargetedService.name}?</h3>

                <div className="modalButton makeMargin center-this" >
                    <button type="button" className="font-this paddingButton marginRight" onClick={() => onCloseDeleteServiceModal()}>Annuller</button>

                    <button type="button" className="font-this paddingButton" onClick={() => onDelete()}>Slet</button>
                </div>

            </div>
                
        </div>
    )

}

//get
const mapStateToProps = (state) => ({
    getReduxTargetedService: state.servicesState.targetedService, //get the state from servicesState -> targetedService
    getReduxServicesArray: state.servicesState.servicesArray,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxShowDeleteServiceModal: (x) =>
        dispatch({ type: 'SHOW_DELETE_SERVICE_MODAL', x}),

    setReduxTargetedService: (x) =>
        dispatch({ type: 'SET_TARGETED_SERVICE', x}),
})

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(DeleteServiceModalHook);
