import React, { useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';

const AddEmployeeModalHook = (props) => {

    // Declare state variables, which we'll call "inputValues"
    const [values, setValues] = useState({ //'inputValues' used to get the data  &  'setInputValues' used to set the data
        name: '',
        title: '',
        description: '',
        status: '',
        selectedImage: null,
        imageUrl: '',
        uploadProgress: 0,
    })

    // Declare state variables for errors
    const [errors, setErrors] = useState({
        name: '',
        title: '',
        description: '',
        selectedImage: null,
    })

    const onCloseAddEmployeeModal = () => {
        props.setReduxShowAddEmployeeModal(false);
    }


    //The input fields need to update the local state of the component by using a onChange handler.
    const onChange = event => {
        if (event.target.name === "status") { //if checkbox
            setValues({
                ...values,
                [event.target.name]: event.target.checked
                //ex. username      :   legolas
            });
        } else {
            setValues({
                ...values,
                [event.target.name]: event.target.value
                //ex. username      :   legolas
            });
        }



        setErrors({
            ...errors,
            [event.target.name]: ''
        })
        props.setReduxIsSaveSuccess(false); //to remove the 'save success' message if displayed
    };


    const onImageSelected = (event) => {
        if (event.target.files[0]) { //(we take the first data [0]) - if there is some data then..
            const selectedImage = event.target.files[0]; //assign the file at [0] to a const called 'image'
            //this.setState(() => ({ selectedImage })); //set the image to our state
            setValues({
                ...values,
                selectedImage: selectedImage,
            })

            setErrors({
                ...errors,
                selectedImage: '',
            })
        }
    }


    const onImageUpload = (event, order) => {

        //const { selectedImage } = this.state;

        //create a new name for the image using a combination of the employees name + utc date
        let date = new Date();
        let utcDate = date.toUTCString();
        let imageName = values.name + "_" + utcDate;

        const uploadTask = props.firebase.storage.ref(`teamImages/${imageName/*selectedImage.name*/}`).put(values.selectedImage); //create a const called 'uploadTask', which will save the image in storage below 'teamImages' and we rename the file, and say 'put()' which is where we provide the image.

        uploadTask.then(() => { //we execute the 'uploadTask' and make a promise using 'then()'
            props.firebase.storage.ref('teamImages').child(imageName/*selectedImage.name*/).getDownloadURL().then(imageUrl => {
                //console.log(imageUrl);
                //this.setState({imageUrl}); //because we are using a '.then()' (promise) and the modal (AddEMployeeModal) will close down before we even set the state (setState), this will result in a warning saying, taht we can't perform a react state update on an unmounted component. There we uncomment it out (we don't even use it for anything) 

                onSaveDataToDb(order, imageUrl);
            })
        })//add catch block to delete image/employee details from db (make sure to remove the data, as they could have something missing)
        /*.catch(error => {
            console.log("error: " + error);
        });*/;

        onCloseAddEmployeeModal();
        event.preventDefault();
    }

    const onSaveDataToDb = (order, imageUrl) => {
        //console.log("From 'onSaveDataToDb'");

        props.firebase.employees().add({
            name: values.name,
            title: values.title,
            description: values.description,
            status: values.status,
            order: order,
            imageUrl: imageUrl,
        })
    }


    //onSave() method will pass all the form data to the Firebase employees API via your authentication interface in the Firebase class:
    const onSave = event => {
        let isValid = validate(); //handle errors

        if (isValid) { //if 'isValid' is 'true'
            try {

                let order;

                try {
                    order = props.getReduxEmployeesArray.length + 1; //we get the number of items in the array and we add +1 (for this new employee we are creating)
                } catch{
                    order = 1; //if length of getReduxEmployeesArray is 0 (in other words 'getReduxEmployeesArray' is null) we assign 'order' 1
                }

                //console.log("order: "+order);


                /* ************************************************
                ******************* Solution 1 ********************
                ************************************************ */
                onImageUpload(event, order);

                /* ************************************************
                ******************* Solution 1 ********************
                ************************************************ */
                //this.onImageUploadUsingOn(event, order);

                // setValues({
                //     name: '',
                //     title: '',
                //     description: '',
                //     status: '',
                // });

                event.preventDefault(); //(prevent a browser reload/refresh) the preventDefault() method on the event prevents a reload of the browser which otherwise would be a natural behavior when using a submit in a form. 

            } catch (error) {
                console.log(error)
            }
        }

        event.preventDefault(); //(prevent a browser reload/refresh) the preventDefault() method on the event prevents a reload of the browser which otherwise would be a natural behavior when using a submit in a form. 
    };


    const validate = () => {
        // console.log("validate")
        //let emailRegex = /\S+@\S+\.\S+/;

        let allErrors = {};

        if (!values.name) {
            allErrors.name = "Navn er påkrævet";
        } else if (values.name.length < 2) {
            allErrors.name = "Navn skal mindst have 2 tegn";
        } else if (values.name.length > 17) {
            allErrors.name = "Navn kan maks indeholde 17 tegn";
        }

        if (!values.title) {
            allErrors.title = "Titel er påkrævet";
        } else if (values.title.length < 2) {
            allErrors.title = "Titel skal mindst have 2 tegn";
        } else if (values.title.length > 35) {
            allErrors.title = "Titel kan maks indeholde 35 tegn";
        }

        if (!values.description) {
            allErrors.description = "Beskrivelse er påkrævet";
        } else if (values.description.length < 2) {
            allErrors.description = "Beskrivelse skal mindst have 2 tegn";
        } else if (values.description.length > 453) {
            allErrors.description = "Beskrivelse kan maks indeholde 453 tegn";
        }

        if (!values.selectedImage) {
            allErrors.selectedImage = "Billede er påkrævet";
        }

        setErrors({
            name: allErrors.name,
            title: allErrors.title,
            description: allErrors.description,
            selectedImage: allErrors.selectedImage,
        });


        if (Object.keys(allErrors).length > 0) { //if allErros length is more than 0 then
            // console.log("return false");
            // console.log(allErrors);
            return false;
        } else {
            // console.log("return true");
            return true;
        }
    }



    return (
        <div>
            <div className="greyOutBackground" onClick={() => onCloseAddEmployeeModal()}></div>
            <div className="modal-container">

                <h3 className="font-this center-this makeMargin">Opret Medarbejder</h3>

                <form onSubmit={onSave} className="font-this">


                    <div className="flex">

                        <div>
                            <div className="makeMargin" >
                                <label>Navn</label>
                                <br></br>
                                <input className={`${errors.name && "inputError"}`} //if we have an error, then set 'inputError' as className
                                    name="name"
                                    value={values.name} //gets a value from the local state
                                    onChange={onChange} //updates the value in the local state with a onChange handler
                                    type="text"
                                    placeholder=""
                                />
                                {errors.name &&
                                    <p className="errorMessage">{errors.name}</p>

                                }
                                {/* <br></br> */}
                            </div>

                            <div className="makeMargin" >
                                <label>Titel</label>
                                <br></br>
                                <input className={`${errors.title && "inputError"}`} //if we have an error, then set 'inputError' as className
                                    name="title"
                                    value={values.title}
                                    onChange={onChange}
                                    type="text"
                                    placeholder=""
                                />
                                {errors.title &&
                                    <p className="errorMessage">{errors.title}</p>

                                }
                                {/* <br></br> */}
                            </div>

                            <div className="makeMargin" >
                                <label>Beskrivelse</label>
                                <br></br>
                                <input className={`${errors.description && "inputError"}`} //if we have an error, then set 'inputError' as className
                                    name="description"
                                    value={values.description}
                                    onChange={onChange}
                                    type="text"
                                    placeholder=""
                                />
                                {errors.description &&
                                    <p className="errorMessage">{errors.description}</p>

                                }
                                {/* <br></br> */}
                            </div>

                        </div>


                        <div>

                            <div className="makeMargin" >
                                <label>Status</label>
                                <br></br>
                                <input className="marginRightSmall"
                                    name="status"
                                    type="checkbox"
                                    checked={values.status}
                                    onChange={onChange}
                                    placeholder=""
                                />
                                <label className="font-this-small" >Aktiv</label>
                                <br></br>
                            </div>

                            <div className="makeMargin" >
                                <label>Billede</label>
                                <br></br>
                                <label className="font-this-smaller">(Billedet skal helst være 300X435)</label>
                                <br></br>
                                <input
                                    type="file"
                                    onChange={onImageSelected}
                                    accept=".png, .jpg, .jpeg"
                                />
                                {errors.selectedImage &&
                                    <p className="errorMessage">{errors.selectedImage}</p>

                                }

                                {/* display 'this.state.imageUrl' if its defined or else display placeholder. We also specify the dimesnions */}
                                {/* <img src={this.state.imageUrl || 'https://via.placeholder.com/250x400.png?text=Billede%20st%C3%B8rrelse'} alt="Image" height="400px" width="250px" /> */}
                            </div>
                        </div>

                    </div>

                    <div className="makeMargin modalButton center-this" >
                        <button type="button" className="font-this paddingButton marginRight" onClick={() => onCloseAddEmployeeModal()}>Annuller</button>

                        <button type="submit" /*disabled={isInvalid}*/ className="font-this paddingButton marginRight" >  {/* Button is disabled if 'isInvalid' is true */}
                            Opret
                        </button>
                    </div>

                    {/*  An optional error message from an error object. The error objects from Firebase have this message
                    property by default, so you can rely on it to display the proper text for your application's user.
                    However, the message is only shown when there is an actual error using a conditional rendering. */}
                    {/* {error && <p>{error.message}</p>} */}

                </form>

            </div>

        </div>
    )

}

//get
const mapStateToProps = (state) => ({
    getReduxEmployeesArray: state.teamState.employeesArray, //get the state from teamsState -> employeesArray
    getReduxIsSaveSuccess: state.aboutUsState.isSaveSuccess,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxShowAddEmployeeModal: (x) =>
        dispatch({ type: 'SHOW_ADD_EMPLOYEE_MODAL', x }),

    setReduxIsSaveSuccess: (x) =>
        dispatch({ type: 'ISSAVESUCCESS_SET', x }),
})

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(AddEmployeeModalHook);
