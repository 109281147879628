import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';

const DeleteEmployeeModalHook = (props) => {

    const onCloseDeleteEmployeeModal = () => {
        props.setReduxTargetedEmployee(null);
        props.setReduxShowDeleteEmployeeModal(false);
    }

    const onDelete = () => {
        let id = props.getReduxTargetedEmployee.id;

        updateOrderNumbers();

        props.setReduxTargetedEmployee(null);
        props.setReduxShowDeleteEmployeeModal(false);

        deletePreviousImage();

        props.firebase.employee(id).delete();

    }

    const updateOrderNumbers = () => {
        let totalLength = props.getReduxEmployeesArray.length;
        let targetedOrder = props.getReduxTargetedEmployee.order;

        // for (let i = 0; i < totalLength; i++) {
        //     console.log("name: " + props.getReduxEmployeesArray[i].name);
        // }

        for (let i = targetedOrder; i < totalLength; i++) {
            let orderOfRowBelow = i + 1; //the 'order' number of the row below is '+1' more (therefore we plus/add)
            let rowBelow = props.getReduxEmployeesArray[orderOfRowBelow - 1]; //then we get the complete data of the row above. We don't have to say '-1' because the 'orderOfRowBelow' is already -1 lower (we need it it -1 lower as arrays starts from '0').

            //decrease the 'order' number of the below row ('employee')
            props.firebase.employee(rowBelow.id).set({  //for the employee in the below row (called 'rowBelow') we:
                ...rowBelow, //we keep all data
                order: orderOfRowBelow - 1, //decrease the 'order' number (so it can go op (smallest first))
            });
        }
    }

    const deletePreviousImage = () => {
        let imageUrl = props.getReduxTargetedEmployee.imageUrl;

        //Delete previous image from firebase storage
        //1. Create a reference from an HTTPS URL
        const httpsReference = props.firebase.storage.refFromURL(imageUrl);
        //2. Delete the filter
        httpsReference.delete().then(function () {
            // File deleted successfully
        }).catch(function (error) {
            // Uh-oh, an error occurred!
        });
    }

    return (
        <div>
            <div className="greyOutBackground" onClick={() => onCloseDeleteEmployeeModal()}></div>
            <div className="delete-modal-container">

                {/* {this.state.error && <p>{this.state.error.message}</p>} */}

                <h3 className="font-this center-this makeMargin">Slet: {props.getReduxTargetedEmployee.name}?</h3>

                <div className="modalButton makeMargin" >
                    <button type="button" className="font-this paddingButton marginRight" onClick={() => onCloseDeleteEmployeeModal()}>Annuller</button>

                    <button type="button" className="font-this paddingButton" onClick={() => onDelete()}>Slet</button>
                </div>

            </div>

        </div>
    )

}

//get
const mapStateToProps = (state) => ({
    getReduxTargetedEmployee: state.teamState.targetedEmployee, //get the state from teamState -> targetedEmployee
    getReduxEmployeesArray: state.teamState.employeesArray,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxShowDeleteEmployeeModal: (x) =>
        dispatch({ type: 'SHOW_DELETE_EMPLOYEE_MODAL', x }),

    setReduxTargetedEmployee: (x) =>
        dispatch({ type: 'SET_TARGETED_EMPLOYEE', x }),
})

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(DeleteEmployeeModalHook);
