import React, { useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../../../Firebase';

const EditServiceModalHook = (props) => {

    // Declare state variables, which we'll call "inputValues"
    const [inputValues, setInputValues] = useState({ //'inputValues' used to get the data  &  'setInputValues' used to set the data
        name: props.getReduxTargetedService.name,
        description: props.getReduxTargetedService.description,
        price: props.getReduxTargetedService.price,
        iconNr: props.getReduxTargetedService.iconNr,
    })

    // Declare state variables for errors
    const [errors, setErrors] = useState({
        name: '',
        description: '',
        price: '',
        iconNr: '',
    })

    const onCloseEditServiceModal = () => {
        props.setReduxTargetedService(null);
        props.setReduxShowEditServiceModal(false);
    }

    //The input fields need to update the local state of the component by using a onChange handler.
    const onChange = event => {
        setInputValues({
            ...inputValues, //we leave the states as they are
            [event.target.name]: event.target.value //and update only this
            //ex. username      :   legolas
        })

        setErrors({
            ...errors,
            [event.target.name]: ''
        })
        props.setReduxIsSaveSuccess(false); //to remove the 'save success' message if displayed
    };


    //onSave() method will pass all the form data to the Firebase services API via your authentication interface in the Firebase class:
    const onSave = event => {
        let isValid = validate(); //handle errors

        if (isValid) { //if 'isValid' is 'true'
            try {
                let id = props.getReduxTargetedService.id;

                props.firebase.service(id).set({
                    name: inputValues.name,
                    description: inputValues.description,
                    price: inputValues.price,
                    iconNr: inputValues.iconNr,
                    order: props.getReduxTargetedService.order,
                })

                let updatedServiceObject = { //we create a new object with the new values
                    id: id,
                    name: inputValues.name,
                    description: inputValues.description,
                    price: inputValues.price,
                    iconNr: inputValues.iconNr,
                    order: props.getReduxTargetedService.order
                }

                props.setReduxTargetedService(updatedServiceObject); // we update the targeted object with the new values

                props.setReduxIsSaveSuccess(true); //to display 'save success' message
                //onCloseEditServiceModal();

            } catch (error) {
                console.log(error)
            }
        }

        event.preventDefault(); //(prevent a browser reload/refresh) the preventDefault() method on the event prevents a reload of the browser which otherwise would be a natural behavior when using a submit in a form. 
    };


    const validate = () => {
        // console.log("validate")
        //let emailRegex = /\S+@\S+\.\S+/;

        let allErrors = {};

        if (!inputValues.name) {
            allErrors.name = "Navn er påkrævet";
        } else if (inputValues.name.length < 3) {
            allErrors.name = "Navn skal mindst have 3 tegn";
        } else if (inputValues.name.length > 38) {
            allErrors.name = "Navn kan maks indeholde 38 tegn";
        }

        if (!inputValues.description) {
            allErrors.description = "Beskrivelse er påkrævet";
        } else if (inputValues.description.length < 3) {
            allErrors.description = "Beskrivelse skal mindst have 3 tegn";
        } else if (inputValues.description.length > 180) {
            allErrors.description = "Beskrivelse kan maks indeholde 180 tegn (helst 128 tegn)";
        }

        if (!inputValues.price) {
            allErrors.price = "Pris er påkrævet";
        } else if (inputValues.price.length < 1) {
            allErrors.price = "Pris skal mindst have 1 tegn";
        } else if (inputValues.price.length > 12) {
            allErrors.price = "Pris kan maks indeholde 12 tegn";
        }

        if (!inputValues.iconNr) {
            allErrors.iconNr = "Icon er påkrævet";
        }

        setErrors({
            name: allErrors.name,
            description: allErrors.description,
            price: allErrors.price,
            iconNr: allErrors.iconNr,
        });

        /*  isValid = false */

        if (Object.keys(allErrors).length > 0) { //if allErros length is more than 0 then
            // console.log("return false");
            return false;
        } else {
            // console.log("return true");
            return true;
        }
    }

    const { getReduxTargetedService, getReduxIsSaveSuccess } = props;

    const isInvalid =
        inputValues.name === getReduxTargetedService.name &&
        inputValues.description === getReduxTargetedService.description &&
        inputValues.price === getReduxTargetedService.price &&
        inputValues.iconNr === getReduxTargetedService.iconNr;

    return (
        <div>
            <div className="greyOutBackground" onClick={() => onCloseEditServiceModal()}></div>
            <div className="modal-container">

                <h3 className="font-this center-this makeMargin">Rediger Service</h3>

                <form onSubmit={onSave} className="font-this">

                    <div className="flex">

                        <div>
                            <div className="makeMargin" >
                                <label>Navn</label>
                                <br></br>
                                <input className={`${errors.name && "inputError"}`} //if we have an error, then set 'inputError' as className
                                    name="name"
                                    value={inputValues.name} //gets a value from the local state
                                    onChange={onChange} //updates the value in the local state with a onChange handler
                                    type="text"
                                    placeholder=""
                                />
                                {errors.name &&
                                    <p className="errorMessage">{errors.name}</p>

                                }
                                {/* <br></br> */}
                            </div>


                            <div className="makeMargin" >
                                <label>Beskrivelse</label>
                                <br></br>
                                <textarea className={`${errors.description && "inputError"}`} //if we have an error, then set 'inputError' as className
                                    name="description"
                                    value={inputValues.description}
                                    onChange={onChange}
                                    type="text"
                                    placeholder=""
                                    rows="5"
                                //cols="20"
                                />
                                {errors.description &&
                                    <p className="errorMessage">{errors.description}</p>

                                }
                                {/* <br></br> */}
                            </div>

                            <div className="makeMargin" >
                                <label>Pris</label>
                                <br></br>
                                <input className={`${errors.price && "inputError"}`} //if we have an error, then set 'inputError' as className
                                    name="price"
                                    value={inputValues.price}
                                    onChange={onChange}
                                    type="text"
                                    placeholder=""
                                />
                                {errors.price &&
                                    <p className="errorMessage">{errors.price}</p>

                                }
                                {/* <br></br> */}
                            </div>
                        </div>




                        <div className="makeMargin" >
                            <label>Icon</label>
                            <br></br>

                            <div className="iconArea flex flexWrap">

                                <div className="iconRightMargin">
                                    <input className="centerRadioButton"
                                        name="iconNr"
                                        value="1"
                                        onChange={onChange}
                                        type="radio"
                                        checked={inputValues.iconNr === "1"} //if iconNr === '1', then check this
                                    />
                                    <div className="multipleIcons">
                                        <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon" />
                                    </div>
                                    <br></br>
                                </div>

                                <div className="iconRightMargin">
                                    <input className="centerRadioButton"
                                        name="iconNr"
                                        value="2"
                                        onChange={onChange}
                                        type="radio"
                                        checked={inputValues.iconNr === "2"}
                                    />
                                    <div className="multipleIcons">
                                        <img className="price-icon" src={require("../../../../images/icons/razor-hair-salon-tool.svg")} alt="icon" />
                                        <img className="price-icon centerIcon" src={require("../../../../images/icons/beard.svg")} alt="icon" />
                                    </div>
                                    <br></br>
                                </div>

                                <div className="iconRightMargin">
                                    <input className="centerRadioButton"
                                        name="iconNr"
                                        value="3"
                                        onChange={onChange}
                                        type="radio"
                                        checked={inputValues.iconNr === "3"}
                                    />
                                    <div className="multipleIcons">
                                        <img className="price-icon" src={require("../../../../images/icons/male-short-hair-shape.png")} alt="icon" />
                                        <img className="price-icon-small centerIcon" src={require("../../../../images/icons/beard.svg")} alt="icon" />
                                    </div>
                                    <br></br>
                                </div>

                                <div className="iconRightMargin">
                                    <input className="centerRadioButton"
                                        name="iconNr"
                                        value="4"
                                        onChange={onChange}
                                        type="radio"
                                        checked={inputValues.iconNr === "4"}
                                    />
                                    <div className="multipleIcons">
                                        <img className="price-icon-big" src={require("../../../../images/icons/scissor-and-comb.svg")} alt="icon" />
                                    </div>
                                    <br></br>
                                </div>

                                <div className="iconRightMargin">
                                    <input className="centerRadioButton"
                                        name="iconNr"
                                        value="5"
                                        onChange={onChange}
                                        type="radio"
                                        checked={inputValues.iconNr === "5"} //if iconNr === '1', then check this
                                    />
                                    <div className="multipleIcons">
                                        <img className="timing-icon" src={require("../../../../images/icons/shaver.svg")} alt="icon" />
                                    </div>
                                    <br></br>
                                </div>

                                <div className="iconRightMargin">
                                    <input className="centerRadioButton"
                                        name="iconNr"
                                        value="6"
                                        onChange={onChange}
                                        type="radio"
                                        checked={inputValues.iconNr === "6"}
                                    />
                                    <div className="multipleIcons">
                                        <img className="timing-icon" src={require("../../../../images/icons/wax.svg")} alt="icon" />
                                    </div>
                                    <br></br>
                                </div>

                                <div className="iconRightMargin">
                                    <input className="centerRadioButton"
                                        name="iconNr"
                                        value="7"
                                        onChange={onChange}
                                        type="radio"
                                        checked={inputValues.iconNr === "7"}
                                    />
                                    <div className="multipleIcons">
                                        <img className="timing-icon" src={require("../../../../images/icons/clock-circular-outline.svg")} alt="icon" />
                                    </div>
                                </div>
                            </div>
                            {errors.iconNr &&
                                <p className="errorMessage">{errors.iconNr}</p>

                            }

                        </div>

                    </div>


                    <div className="makeMargin modalButton center-this" >
                        <button type="button" className="font-this paddingButton marginRight" onClick={() => onCloseEditServiceModal()}>Annuller</button>

                        <button type="submit" disabled={isInvalid} className="font-this paddingButton marginRight" >  {/* Button is disabled if 'isInvalid' is true */}
                            Gem Ændringer
                        </button>

                        <br></br>

                        {getReduxIsSaveSuccess &&
                            <label className="inputStyling sucessMessage">Gemt successfuldt</label>
                        }
                    </div>

                    {/*  An optional error message from an error object. The error objects from Firebase have this message
                    property by default, so you can rely on it to display the proper text for your application's user.
                    However, the message is only shown when there is an actual error using a conditional rendering. */}
                    {/* {error && <p>{error.message}</p>} */}

                </form>

            </div>

        </div>
    )

}

//get
const mapStateToProps = (state) => ({
    getReduxTargetedService: state.servicesState.targetedService, //get the state from servicesState -> servicesArray
    getReduxIsSaveSuccess: state.aboutUsState.isSaveSuccess,
})

//set
const mapDispatchToProps = dispatch => ({
    setReduxShowEditServiceModal: (x) =>
        dispatch({ type: 'SHOW_EDIT_SERVICE_MODAL', x }),

    setReduxTargetedService: (x) =>
        dispatch({ type: 'SET_TARGETED_SERVICE', x }),

    setReduxIsSaveSuccess: (x) =>
        dispatch({ type: 'ISSAVESUCCESS_SET', x }),
})

export default compose(
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(EditServiceModalHook);
