//App --> index

import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

//components
// import LandingPage from '../Landing';

import PasswordForgetPage from '../PasswordForget';
// import HomePage from '../Home';
// import AccountPage from '../Account';
// import AdminPage from '../Admin';

import './App.css';
//components
import Navigation from '../Navigation';
import OnePageApp from '../OnePageApplication/OnePageApplication';
import SignInPage from '../SignIn';
//  import SignUpPage from '../SignUp';
//import AdminAreaNav from '../AdminArea/AdminAreaNav.js';
import AdminHome from '../AdminArea/AdminPages/AdminHome/AdminHome';
import AdminAboutUs from '../AdminArea/AdminPages/AdminAboutUs/AdminAboutUs';
//import AdminPrices from '../AdminArea/AdminPages/AdminPrices/AdminPrices';
import AdminPricesHook from '../AdminArea/AdminPages/AdminPrices/AdminPricesHook';
//import AdminTeam from '../AdminArea/AdminPages/AdminTeam/AdminTeam';
import AdminTeamHook from '../AdminArea/AdminPages/AdminTeam/AdminTeamHook';
import AdminContact from '../AdminArea/AdminPages/AdminContact/AdminContact';


import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';



const App = () => (
/*class App extends React.Component {
    

    render() {
        return (*/
            <Router>
                <div>
                    {/* Importing Font Awesome */}
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>

                    <div /*className="App"*/ /*className="overflow"*/>
                        <div /*className="container"*/>

                            <Route exact path="/" component={Navigation} />
                            {/*  
                                <Route exact path="/"/*{ROUTES.HOME}*/ /*component={OnePageApp} />
                            */}
                            <Route exact path="/"/*{ROUTES.HOME}*/ component={OnePageApp} />
                            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                            {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
                            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                            
                            <Route exact path={ROUTES.ADMIN_AREA_HOME} component={AdminHome} />
                            <Route exact path={ROUTES.ADMIN_AREA_ABOUT_US} component={AdminAboutUs} />
                            <Route exact path={ROUTES.ADMIN_AREA_PRICES} component={AdminPricesHook} />
                            <Route exact path={ROUTES.ADMIN_AREA_TEAM} component={AdminTeamHook} />
                            <Route exact path={ROUTES.ADMIN_AREA_CONTACT} component={AdminContact} />

                        </div>
                    </div>
                </div>
            </Router>
        /*)
    };
}*/
);

//export default withFirebase(App);
export default withAuthentication(App);
