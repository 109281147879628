
const INITIAL_STATE = {
    address: '',
    phone: '',
    email: '',
    openHours: '',
    isSaveSuccess: false,
};



function contactReducer(state = INITIAL_STATE, action) {

    switch(action.type) {
        case 'ADDRESS_SET': return { 
            ...state,
            address: action.x
        }
        
        case 'PHONE_SET': return {
            ...state,
            phone: action.x
        }

        case 'EMAIL_SET': return {
            ...state,
            email: action.x
        }

        case 'OPENHOURS_SET': return {
            ...state,
            openHours: action.x
        }

        case 'ISSAVESUCCESS_SET': return {
            ...state,
            isSaveSuccess: action.x
        }

        default:
            return state; //if we don't have the type defined in the reducer, we just return the state
    }
}

export default contactReducer;
