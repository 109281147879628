import React from 'react'
/* With React Redux, your components never access the store directly - connect does it for you. React Redux gives you two ways to let components dispatch actions:

By default, a connected component receives props.dispatch and can dispatch actions itself.
connect can accept an argument called mapDispatchToProps, which lets you create functions that dispatch when called, and pass those functions as props to your component.
The mapDispatchToProps functions are normally referred to as mapDispatch for short, but the actual variable name used can be whatever you want. */
import { connect } from 'react-redux';
import { compose } from 'recompose';

//import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

/* To keep the App component clean and concise, we extract the session handling
for the authenticated user to a separate higher-order component (the one we have here).

We move all logic that deals with the authenticated user from the App component to it */
const withAuthentication = (Component) => { //this is our higher-order component
    class WithAuthentication extends React.Component {

        constructor(props) {
            super(props);
    
            this.props.onSetAuthUser(JSON.parse(localStorage.getItem('authUserLocalStorage')));

            //this.state = {
            //    authUser: JSON.parse(localStorage.getItem('authUserLocalStorage')) //state of authenticated user (we retrieve the state from the local storage)
                /* if there is no 'authUserLocalStorage' in the local storage, the local state
                will stay null and everything will remain as before.
                
                However, if the authenticated user is in the local storage because it was stored
                via our Firebase listener's function, we can use it in the component's constructor.
                Since the format of the authenticated user in the local storage is JSON, we need to
                transform it into a JavaScript object again. Ultimately, someone using our application
                can refresh the browser, but also close the browser/tab and open it after a while,
                and it will still see them as an authenticated user. */
            //};
        }
    
        componentDidMount() { //componentDidMount() is invoked immediately after a component is mounted (inserted into the tree).
            //console.log("****" + this.props);
    
            //higher-order component which cares about storing the merged user in the local state and distributing it to other components via React's Context Provider component.
            this.listener = this.props.firebase.onAuthUserListener(
                //next
                authUser => {
                    /* Every time Firebase's listener is invoked, the authenticated user is
                    stored in the local state, ready to be passed to React's Context
                    API, and it's also stored in the browser's local storage. You can use the
                    local storage's API with setItem and removeItem to store and delete
                    something identified by a key. You also need to format the authenticated
                    user to JSON before you can put it into the local storage of the browser. */
                    localStorage.setItem('authUserLocalStorage', JSON.stringify(authUser)); //using the browser's local storage for the authenticated user
                    //this.setState({ authUser }); //then setState to authUser (which will be true)
                    this.props.onSetAuthUser(authUser) //set the state to authUser (which will be true) using redux
                    //localStorage.setItem('lastSignInTime', this.props.firebase.firestore.FieldValue.serverTimestamp());
                    //new Date().getTime() / 1000
                },
                //fallback
                () => {
                    localStorage.removeItem('authUserLocalStorage'); //using the browser's local storage for the authenticated user
                    //this.setState({ authUser: null });  //else set authUser to null
                    this.props.onSetAuthUser(null) //set the state to authUser to 'null' using redux
                }
            );

            //We no longer use this as we have implemented the 'onAuthStateChanged()' function in 'firebase.js'
            /* The 'onAuthStateChanged()' firebase function is called everytime something changes for the authenticated user.
            It is called when a user signs up, signs in, and signs out. If a user signs out, the authUser object becomes null,
            so the authUser property in the local state is set to null and all components depending on it adjust their behavior. */
    /*        this.listener = this.props.firebase.firebaseAuth.onAuthStateChanged(authUser => { //why do we say 'this.props'? Did pase 'firebase' in props? if yes when?  -*-
                
                //authUser //if authUser is true
                //? this.setState({ authUser }) //then setState to authUser (which will be true))
                //: this.setState({ authUser: null }); //else set authUser to null
            
                if (authUser) { //if authUser is true

                    /* We are using the users reference from our Firebase class to
                    attach a listener. The listener is called once(). The once() method
                    registers a listener that would be called only once.  */
    /*                this.props.firebase
                    .user(authUser.uid)
                    .once('value')
                    .then(snapshot => {
                        const dbUser = snapshot.val();

                        // default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }

                        // merge auth and db user
                        //we merge everything from the database user with the unique identifier and email from the authenticated user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            ...dbUser //add the rest from dbUser (You may need more properties from the authenticated user later, but at this point the unique identifier and the email are sufficient.)
                        };

                        this.setState({ authUser }); //then setState to authUser (which will be true))
                    });
                } else {
                    this.setState({ authUser: null }); //else set authUser to null
                }
            
            
                
            });*/
        }
    
        /* This method is called when a component is being removed from the DOM.
        componentWillUnmount() is invoked immediately before a component is unmounted and destroyed.
         Perform any necessary cleanup in this method, such as invalidating timers, canceling network requests,
        or cleaning up any subscriptions that were created in componentDidMount(). */
        componentWillUnmount() { 
            /* to avoid memory leaks that lead to performance issues, (https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component)
            we'll remove the listener if the component unmounts */
            this.listener(); //-*-
        }

        render() {
            return (
                //<AuthUserContext.Provider value={this.state.authUser}>{/* We use the context to provide the authenticated user to components that are interested in it */}
                    <Component {...this.props} />
                //</AuthUserContext.Provider>
            );
        }
    }


    const mapDispatchToProps = dispatch => ({ //used for dispatching actions to the store (redux)
        onSetAuthUser: authUser => //name of props 'onSetAuthUser' and contains the 'authUser' state
        dispatch({ type: 'AUTH_USER_SET', authUser }),
    });

    //return withFirebase(WithAuthentication);
    return compose(
        withFirebase,
        connect(null, mapDispatchToProps) //With React Redux, your components never access the store directly - connect does it for you.
    )(WithAuthentication);


};


export default withAuthentication;