import React, { Component } from 'react'
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../../../Session';
import AdminAreaNav from '../../AdminAreaNav';

class AdminHome extends Component {
    render() {
        return (
            <div>
                <AdminAreaNav />
                <h3 className="admin-title">Hjem (Admin)</h3>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

//export default AdminHome
export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(AdminHome);