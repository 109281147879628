/* Like the withAuthentication higher-order component, there is a withAuthorization higher-order component
to shield the authorization business logic from your components. It can be used on any component that needs
to be protected with authorization (e.g. home page, account page). */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
//import AuthUserContext from './context';
import * as ROUTES from '../../constants/routes';


const withAuthorization = (condition) => Component => { //higher-order component is taking a component as input and returning it as output. 
    //  The higher-order component receives a condition function passed as parameter.
    
    class WithAuthorization extends React.Component {

        componentDidMount() { //componentDidMount() is invoked immediately after a component is mounted (inserted into the tree).
            
            //higher-order component which cares about redirects
            this.listener = this.props.firebase.onAuthUserListener(
                //next
                authUser => {
                    //If the conditions are not met, we redirect the user. If the conditions are met, the user stays on the page component enhanced by the authorization higher-order component
                    if (!condition(authUser)) { //if not authenticated - (The authenticated user is either a authUser object or null)
                        //re-direct to login page (To redirect a user, the higher-order component has access to the history object of the Router using the in-house withRouter() higher-order component from the React Router library)
                        this.props.history.push(ROUTES.SIGN_IN); 
                    }
                },
                //fallback
                () => {
                    //if user is null, then redirect
                    this.props.history.push(ROUTES.SIGN_IN);
                }
            );

            //We no longer use this as we have implemented the 'onAuthStateChanged()' function in 'firebase.js'
            /* The 'onAuthStateChanged()' firebase function is called everytime something changes for the authenticated user.
            It is called when a user signs up, signs in, and signs out. If a user signs out, the authUser object becomes null,
            so the authUser property in the local state is set to null and all components depending on it adjust their behavior. */
        /*    this.listener = this.props.firebase.firebaseAuth.onAuthStateChanged(
                authUser => {
                    if (authUser) { //if user is not null


                        /* We are using the users reference from our Firebase class to
                        attach a listener. The listener is called once(). The once() method
                        registers a listener that would be called only once.  */
        /*                this.props.firebase
                            .user(authUser.uid)
                            .once('value')
                            .then(snapshot => {
                                const dbUser = snapshot.val(); //-*-
                                
                                //default empty roles
                                if (!dbUser.roles) {
                                    dbUser.roles = {};
                                }

                                //merge auth and db user
                                //we merge everything from the database user with the unique identifier and email from the authenticated user
                                authUser = {
                                    uid: authUser.uid,
                                    email: authUser.email,
                                    ...dbUser //add the rest from dbUser (You may need more properties from the authenticated user later, but at this point the unique identifier and the email are sufficient.)
                                };

                                //If the conditions are not met, we redirect the user. If the conditions are met, the user stays on the page component enhanced by the authorization higher-order component
                                if (!condition(authUser)) { //if not authenticated - (The authenticated user is either a authUser object or null)
                                    //re-direct to login page (To redirect a user, the higher-order component has access to the history object of the Router using the in-house withRouter() higher-order component from the React Router library)
                                    this.props.history.push(ROUTES.SIGN_IN); 
                                }
                            });
                    } else { //if user is null, then redirect
                        this.props.history.push(ROUTES.SIGN_IN);
                    }
                }
            );*/
        }

        componentWillUnmount() {
            this.listener();
        }

        
        render() {
            return (
                //if authUser is true, then return the Component (page), that the user is trying to access, else do nothing
                condition(this.props.authUser) ? <Component {...this.props} /> : null
            )
            
            // return (
            //     //displays the protected component if user is authenticated (e.g. home page, account page)
            //     <AuthUserContext.Consumer>{/* the withAuthorization (HoC) component uses the context to consume the authenticated user */}
            //     {/* if authUser is true, then return the Component (page), that the user is trying to access, else do nothing */}
            //     {authUser =>
            //         condition(authUser) ? <Component {...this.props} /> : null
            //     }
            //     </AuthUserContext.Consumer>
            // );
        }
    }

    /* mapStateToProps is used for selecting the part of the data from the store that the connected component needs.

    - It is called every time the store state changes.
    - It receives the entire store state, and should return an object of data this component needs. */
    const mapStateToProps = state => ({
        authUser: state.sessionState.authUser //get the state from sessionStatate -> authuser
    });

    return compose(
        withRouter,
        withFirebase,
        connect(mapStateToProps)
    )(WithAuthorization);
}


export default withAuthorization;
